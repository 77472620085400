import {
  fromISODateString,
  OPERATION_KIND_MAP,
  toCurrencyDescriptionFromCrypto,
  useRoot,
} from '@ncwallet-app/core';
import type {WalletTransactions} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import {useMemo} from 'react';

// eslint-disable-next-line import-x/prefer-default-export
export const useProceedTransaction = (
  transaction: WalletTransactions.AggregationItem,
) => {
  const {currencyStore} = useRoot();
  const cryptoCurrency = currencyStore.getCryptoCurrency(
    transaction.currencyCode,
  );
  const crypto =
    cryptoCurrency && toCurrencyDescriptionFromCrypto(cryptoCurrency);

  return useMemo(
    () => ({
      status: transaction.status,
      kind: OPERATION_KIND_MAP[transaction.kind],
      cryptoCode: transaction.currencyCode,
      cryptoName: crypto?.name,
      cryptoFractionDigits: crypto?.fractionDigits,
      cryptoValue: transaction.amount,
      decimals: crypto?.fractionDigits,
      date: fromISODateString(transaction.createdAt),
    }),
    [transaction, crypto?.name, crypto?.fractionDigits],
  );
};
