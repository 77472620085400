/* eslint-disable @typescript-eslint/unbound-method */
import {useShareAvailable} from '@ncwallet-app/core';
import type {LargeSwitchParamList} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {useHeaderHeight} from '@react-navigation/elements';
import type {PartialState} from '@react-navigation/native';
import type {NavigationState} from '@react-navigation/routers';
import {CommonActions} from '@react-navigation/routers';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Platform, Share} from 'react-native';

import {useGeneratePayMeLinkBindingState} from '../../../../../Navigation/HomeStack/GeneratePayMeLinkBinding/useGeneratePayMeLinkBindingState';
import {EXTRA_BOTTOM_OFFSET} from '../../../../../screens/constants';
import GeneratePayMeLinkScreen from '../../../../../screens/GeneratePayMeLinkScreen';
import type {LargeSwitchBindingProps} from '../LargeSwitchBindingProps';

export type GeneratePayMeLinkBingingProps =
  LargeSwitchBindingProps<'GeneratePayMeLink'>;

export default observer(function GeneratePayMeLinkBinding({
  navigation,
  route,
}: GeneratePayMeLinkBingingProps) {
  const {amount, currency, address} = route.params;
  const state = useGeneratePayMeLinkBindingState({
    amount,
    currency,
    address,
  });

  const onBackPress = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }

    const switchState: PartialState<NavigationState<LargeSwitchParamList>> = {
      index: 0,
      routes: [{name: 'ShowQrToReceiveCrypto', params: route.params}],
    };
    navigation.dispatch(CommonActions.reset(switchState));
  }, [navigation, route.params]);

  const share = useCallback(async (data: string) => {
    try {
      await Share.share({
        title: 'Share this',
        message: data,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const header = useHeaderHeight();

  const shareShown = useShareAvailable();

  return (
    <GeneratePayMeLinkScreen
      cryptoCurrency={state.currency}
      shareShown={shareShown}
      onShare={share}
      keyboardAvoiding
      behavior={Platform.OS === 'web' ? 'position' : 'padding'}
      keyboardVerticalOffset={header}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      link={state.link}
      amount={state.amount}
      currency={state.currencyCode}
      error={state.error}
      savedLink={state.savedLink}
      setAmount={state.setAmount}
      setLink={state.setLink}
      generateLink={state.generateLink}
      onBackPress={onBackPress}
      onSaveName={state.onSaveName}
      onMakeAvailableEdit={state.onMakeAvailableEdit}
      isAvailableEdit={state.isAvailableEdit}
    />
  );
});
