import type {CurrencyDescription, WalletLimit} from '@ncwallet-app/core';
import {
  formatCryptoValue,
  useStrings,
  useStyles,
  variance,
} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui/src';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';

import LimitActions from '../screens/LgLimitsSettingsScreen/LimitCard/ShowLimitCard/LimitActions';
import LimitNewInfo from './LimitNewInfo';
import {useDiffFormattedTime} from './useDiffFormattedTime';
import WalletLimitProgressBar, {
  computeRemainingLimitPercent,
  mapLimitValueToColor,
} from './WalletLimitProgressBar';

type LimitInfoProps = {
  limitTitle: string;
  newLimitTitle: string;
  limit: WalletLimit;
  newLimit?: WalletLimit;
  remainingTime: string;
  currency: CurrencyDescription;
  onDeletePress: (l: WalletLimit) => void;
  onEditPress: () => void;
  onDeleteWaitingForActivationLimitPress: () => void;
  onCancelDeletePress: () => void;
};

export default observer(function LimitInfo(props: LimitInfoProps) {
  const {limitTitle, newLimitTitle, limit, remainingTime, newLimit, currency} =
    props;
  const strings = useStrings();
  const remainLimitPercent = useMemo(
    () =>
      computeRemainingLimitPercent(
        BigNumber(limit.limit).toNumber(),
        BigNumber(limit.accrued).toNumber(),
      ),
    [limit],
  );
  const accruedValueStyles = useStyles(theme => ({
    color: {
      color: mapLimitValueToColor(theme, remainLimitPercent),
    },
  }));
  const formattedTime = useDiffFormattedTime(limit.deleted_at);
  return (
    <LimitInfoContainer>
      <LimitInfoSingle withBackground={!!newLimit}>
        <LimitInfoTitle>{limitTitle}</LimitInfoTitle>
        <LimitInfoCrypto>
          <LimitInfoAccruedValue style={accruedValueStyles.color}>
            {formatCryptoValue(limit.accrued, currency.fractionDigits)}
          </LimitInfoAccruedValue>{' '}
          / {formatCryptoValue(limit.limit, currency.fractionDigits)}
        </LimitInfoCrypto>
        <WalletLimitProgressBar total={limit.limit} value={limit.accrued} />
        <WalletLimitRemainTimeValue>
          {strings['limitsSettingsScreen.remainingLimitTime']} {remainingTime}
        </WalletLimitRemainTimeValue>
        {limit.deleted_at && (
          <>
            <SizedBox />
            <WalletLimitRemainTimeTitle>
              {strings['limitsSettingsScreen.disableLimitLabel']}
            </WalletLimitRemainTimeTitle>
            <WalletLimitRemainTimeValue>
              {strings['limitsSettingsScreen.applyIn']} {formattedTime}
            </WalletLimitRemainTimeValue>
          </>
        )}
        {limit.deleted_at && (
          <>
            <SizedBox />
            <LimitAction
              variant={ButtonVariant.Danger}
              title={strings['currencyLimitScreen.disableButton']}
              onPress={props.onCancelDeletePress}
            />
          </>
        )}
        {!newLimit && !limit.deleted_at && (
          <>
            <SizedBox />
            <LimitActions
              onEditPress={props.onEditPress}
              onDeletePress={() => {
                props.onDeletePress(limit);
              }}
            />
          </>
        )}
      </LimitInfoSingle>
      {newLimit && (
        <LimitNewInfo
          onCancel={props.onDeleteWaitingForActivationLimitPress}
          limit={newLimit}
          title={newLimitTitle}
        />
      )}
    </LimitInfoContainer>
  );
});

const LimitInfoContainer = variance(View)(theme => ({
  root: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    textAlign: 'center',
  },
}));

const WalletLimitRemainTimeTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    color: theme.palette.error,
    textAlign: 'center',
  },
}));

const WalletLimitRemainTimeValue = variance(Text)(theme => ({
  root: {
    marginTop: 10,
    ...theme.fontByWeight('500'),
    fontSize: 13,
    lineHeight: 16,
    color: theme.palette.textAdditional3,
    textAlign: 'center',
  },
}));

const LimitInfoSingle = variance(View)(theme => ({
  root: {
    paddingHorizontal: 10,
    paddingTop: 20,
    paddingBottom: 10,
  },
  withBackground: {
    backgroundColor: theme.palette.uiPrimary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
  },
}));

const LimitInfoTitle = variance(Text)(theme => ({
  root: {
    marginBottom: 10,
    ...theme.fontByWeight('700'),
    fontSize: 14,
    lineHeight: 16,
    color: theme.palette.textPrimary,
    textAlign: 'center',
  },
}));

const LimitInfoCrypto = variance(Text)(theme => ({
  root: {
    marginBottom: 15,
    ...theme.fontByWeight('700'),
    fontSize: 18,
    lineHeight: 18,
    color: theme.palette.textAdditional2,
    textAlign: 'center',
  },
}));

const LimitInfoAccruedValue = variance(Text)(theme => ({
  root: {
    color: theme.palette.error,
  },
  success: {
    color: theme.palette.success,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const LimitAction = variance(Button)(() => ({
  root: {
    marginHorizontal: 10,
  },
}));
