/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain,@typescript-eslint/no-non-null-assertion */
import type {
  CryptoCurrencyCode,
  CurrencyDescription,
  WalletLimit,
} from '@ncwallet-app/core';
import {useStrings, WalletLimitPeriod} from '@ncwallet-app/core';
import {CurrencyCircleLogo} from '@ncwallet-app/ui';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';

import LimitInfo from '../../../../shared/LimitInfo';
import LimitInfoUnset from '../../../../shared/LimitInfoUnset';
import {useComputeEndPeriodLimitTime} from '../../../../shared/useComputePeriodLimitTime';
import {LimitCard} from '../../shared/LimitCard';
import {LimitCardBody} from '../../shared/LimitCardBody';
import {
  LimitCardHeader,
  LimitCardHeaderCurrency,
  LimitCardHeaderCurrencyTitle,
  LimitCardHeaderTitle,
} from '../../shared/LimitCardHeader';
import SetLimitTabs from '../CreateLimitForm/SetLimitTabs';

export type ShowLimitCardProps = {
  getDailyActiveLimit: () => WalletLimit | undefined;
  getWaitingForActivationDailyLimit: () => WalletLimit | undefined;
  getActiveMonthlyLimit: () => WalletLimit | undefined;
  getWaitingForActivationMonthlyLimit: () => WalletLimit | undefined;
  onDeletePress: (l: WalletLimit) => void;
  onChangePress: (l: WalletLimit) => void;
  onDeleteWaitingForActivationLimitPress: (l: WalletLimit) => void;
  onCancelDeletePress: (l: WalletLimit) => void;
  period: WalletLimitPeriod;
  onCreatePress: (
    currency: CryptoCurrencyCode,
    period: WalletLimitPeriod,
    perOnePeriodOnly?: boolean,
  ) => void;
  currency: CurrencyDescription | undefined;
};

export default observer(function ShowLimitCard(props: ShowLimitCardProps) {
  const strings = useStrings();
  const dailyLimit = props.getDailyActiveLimit();
  const monthlyLimit = props.getActiveMonthlyLimit();
  const newDailyLimit = props.getWaitingForActivationDailyLimit();
  const newMonthlyLimit = props.getWaitingForActivationMonthlyLimit();
  const [dailyRemainingTime, monthlyRemainingTime] =
    useComputeEndPeriodLimitTime();

  const [period, setPeriod] = useState(props.period);
  useEffect(() => {
    setPeriod(props.period);
  }, [props.period]);

  return (
    <LimitCard>
      <LimitCardHeader>
        <LimitCardHeaderTitle>
          {strings['limitsSettingsScreen.limitOF']}
        </LimitCardHeaderTitle>
        <LimitCardHeaderCurrency>
          {props.currency && (
            <CurrencyCircleLogo code={props.currency.code} size={20} />
          )}
          <LimitCardHeaderCurrencyTitle>
            {props.currency?.name}
          </LimitCardHeaderCurrencyTitle>
        </LimitCardHeaderCurrency>
      </LimitCardHeader>
      <LimitCardBody>
        <SetLimitTabs period={period} onTabPress={setPeriod} />
        {period === WalletLimitPeriod.Daily && (
          <>
            {isNil(dailyLimit) ? (
              <LimitInfoUnset
                limitTitle={strings['limitsSettingsScreen.limitDailyInfo']}
                onAction={() => {
                  props.onCreatePress(
                    props.currency?.code! as CryptoCurrencyCode,
                    period,
                    true,
                  );
                }}
              />
            ) : (
              <LimitInfo
                currency={props.currency!}
                limitTitle={strings['limitsSettingsScreen.limitDailyInfo']}
                newLimitTitle={
                  strings['limitsSettingsScreen.limitDailyInfoNew']
                }
                limit={dailyLimit}
                newLimit={newDailyLimit}
                remainingTime={dailyRemainingTime}
                onEditPress={() => {
                  props.onChangePress(dailyLimit);
                }}
                onDeletePress={() => {
                  props.onDeletePress(dailyLimit);
                }}
                onDeleteWaitingForActivationLimitPress={() => {
                  props.onDeleteWaitingForActivationLimitPress(newDailyLimit!);
                }}
                onCancelDeletePress={() => {
                  props.onCancelDeletePress(dailyLimit);
                }}
              />
            )}
          </>
        )}
        {period === WalletLimitPeriod.Monthly && (
          <>
            {isNil(monthlyLimit) ? (
              <LimitInfoUnset
                limitTitle={strings['limitsSettingsScreen.limitMonthlyInfo']}
                onAction={() => {
                  props.onCreatePress(
                    props.currency?.code! as CryptoCurrencyCode,
                    period,
                    true,
                  );
                }}
              />
            ) : (
              <LimitInfo
                currency={props.currency!}
                limitTitle={strings['limitsSettingsScreen.limitMonthlyInfo']}
                newLimitTitle={
                  strings['limitsSettingsScreen.limitMonthlyInfoNew']
                }
                limit={monthlyLimit}
                newLimit={newMonthlyLimit}
                remainingTime={monthlyRemainingTime}
                onEditPress={() => {
                  props.onChangePress(monthlyLimit);
                }}
                onDeleteWaitingForActivationLimitPress={() => {
                  props.onDeleteWaitingForActivationLimitPress(
                    newMonthlyLimit!,
                  );
                }}
                onDeletePress={() => {
                  props.onDeletePress(monthlyLimit);
                }}
                onCancelDeletePress={() => {
                  props.onCancelDeletePress(monthlyLimit);
                }}
              />
            )}
          </>
        )}
      </LimitCardBody>
    </LimitCard>
  );
});
