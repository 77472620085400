/* eslint-disable @typescript-eslint/unbound-method */
import type {
  CryptoAddress,
  CryptoCurrencyCode,
  CurrencyDescription,
  DecimalString,
  ShareMessageType,
} from '@ncwallet-app/core';
import {
  useShareAvailable,
  useStrings,
  useStyles,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {getOSKind, OSKind} from '@ncwallet-app/core/src/util/getOSKind';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {
  LG_BREAKPOINT,
  SafeAreaScrollView,
  useIsDimensions,
  WalletHeader,
} from '@ncwallet-app/ui';
import {ReceiveSvg} from '@ncwallet-app/ui/src/assets/svg/colored';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';
import {Platform, Text, View} from 'react-native';
import type {ScrollView} from 'react-native-gesture-handler';

import type {BuyLinkBindingState} from '../../Navigation/HomeStack/BuyLinkBinding/BuyLinkBindingState';
import {AddressTabView} from './AddressTabView';
import BuyTabView from './BuyTabView';
import {ReceiveCryptoTabKey} from './ReceiveCryptoTabKey';
import {Tabs} from './Tabs';
import {UsernameTabView} from './UsernameTabView';
import {useScrollWhenKeyboardShown} from './useScrollWhenKeyboardShown';

export type ReceiveCryptoScreenProps = SafeAreaScrollViewProps & {
  walletName: string;
  contractType: string | null | undefined;
  address: CryptoAddress;
  cryptoCurrency?: CurrencyDescription;
  cryptoValue?: DecimalString;
  fiatCurrency?: CurrencyDescription;
  fiatValue?: DecimalString;
  currencyCode?: CryptoCurrencyCode;
  userID: string;
  goToAddresses: () => void;
  onCommentSubmit: () => void;
  addressNameShown: boolean;
  addressName?: string;
  addressType?: AddressType;
  networkCurrency?: CryptoCurrencyCode;
  goToGenerateLink: () => void;
  comment: string;
  onChangeComment: (comment: string) => void;
  onSaveCommentPress: () => void;
  copy: (data: string) => void;
  showQr?: () => void;
  isBitcoinDefaultNetwork: boolean;
  onAddressTypePress: () => void;
  shareMessage: ShareMessageType;
  buyState?: BuyLinkBindingState;
  onCurrencyPress: () => void;
  onTabChange: (key: ReceiveCryptoTabKey) => void;
  activeTab: ReceiveCryptoTabKey;
  goToRoot: () => void;
};

export default observer(function ReceiveCryptoScreen(
  props: ReceiveCryptoScreenProps,
) {
  const {
    walletName,
    address,
    cryptoCurrency,
    cryptoValue,
    fiatCurrency,
    fiatValue,
    addressNameShown,
    networkCurrency,
    addressName,
    addressType,
    currencyCode,
    copy,
    userID,
    goToAddresses,
    onAddressTypePress,
    showQr,
    goToGenerateLink,
    isBitcoinDefaultNetwork,
    buyState,
    onCurrencyPress,
    shareMessage,
    activeTab,
    onTabChange,
    goToRoot,
    ...rest
  } = props;
  const isLg = useIsDimensions('lg');
  const shareAvailable = useShareAvailable();

  const strings = useStrings();
  const styles = useReceiveStyles();
  const ref = useRef<ScrollView>(null);
  const theme = useTheme();

  const walletHeader = (
    <WalletHeader
      walletName={walletName}
      cryptoCurrency={cryptoCurrency}
      cryptoValue={cryptoValue}
      fiatCurrency={fiatCurrency}
      fiatValue={fiatValue}
      onCurrencyPress={onCurrencyPress}
    />
  );

  useScrollWhenKeyboardShown(ref);

  return (
    <SafeAreaScrollView
      ref={ref}
      style={styles.root}
      contentContainerStyle={styles.container}
      {...rest}>
      <Inner>
        <Header>
          {isLg && (
            <HeaderTitle>
              <ReceiveSvg color={theme.palette.textMain} />
              <SizedBox />
              {strings['receiveCryptoScreen.wideHeader']}
            </HeaderTitle>
          )}
          {buyState && buyState.isDepositAllowed && (
            <Tabs
              style={styles.rowTab}
              activeTabKey={activeTab}
              onTabPress={onTabChange}
            />
          )}
        </Header>

        {activeTab === ReceiveCryptoTabKey.Receive && (
          <AddressTabView
            walletHeader={walletHeader}
            isBitcoinDefaultNetwork={isBitcoinDefaultNetwork}
            shareShown={shareAvailable}
            onAddressTypePress={onAddressTypePress}
            networkCurrency={networkCurrency}
            goToGenerateLink={goToGenerateLink}
            walletAddress={address}
            showNetwork={cryptoCurrency?.showNetwork || currencyCode === 'BTC'}
            currencyCode={currencyCode}
            networks={cryptoCurrency?.networks}
            onCopy={copy}
            showQr={showQr}
            onFocus={ref.current ? ref.current.scrollTo : undefined}
            onShare={shareMessage}
            onAddresses={goToAddresses}
            addressType={addressType}
            addressNameShown={addressNameShown}
            addressName={addressName}
            comment={props.comment}
            onChangeComment={props.onChangeComment}
            onSaveCommentPress={props.onSaveCommentPress}
            contractType={props.contractType}
          />
        )}

        {activeTab === ReceiveCryptoTabKey.Username && (
          <UsernameTabView
            walletHeader={walletHeader}
            onCopy={copy}
            goToAddresses={goToAddresses}
            onShare={shareMessage}
            userData={userID}
            shareShown={shareAvailable}
          />
        )}

        {activeTab === ReceiveCryptoTabKey.Buy && buyState && (
          <BuyTabView
            cryptoCurrency={cryptoCurrency}
            walletHeader={walletHeader}
            addressName={addressName}
            addressNameShown={addressNameShown}
            networks={cryptoCurrency?.networks}
            contractType={props.contractType}
            shareShown={shareAvailable}
            addressType={addressType}
            walletAddress={address}
            onShare={shareMessage}
            onAddresses={goToAddresses}
            onAddressTypePress={onAddressTypePress}
            amount={buyState.amount}
            currencyCode={currencyCode}
            errorText={buyState.errorText}
            setAmount={buyState.setAmount}
            onSubmit={buyState.onSubmit}
            disabled={buyState.disabled}
            goToRoot={goToRoot}
          />
        )}
      </Inner>
    </SafeAreaScrollView>
  );
});

const useReceiveStyles = () => {
  const OS = getOSKind();
  return useStyles(theme => ({
    root: {
      ...Platform.select({
        web: {
          overflow: 'visible',
        },
      }),
    },
    container: {
      overflow: 'visible',
      ...Platform.select({
        web: {
          paddingBottom:
            OS === OSKind.IOs ? 100 : OS === OSKind.Android ? 50 : 0,
        },
      }),

      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          backgroundColor: theme.palette.uiPrimary,
          paddingBottom: 0,
        },
      }),
    },
    rowTab: {
      marginLeft: 0,
      marginBottom: 0,
      marginRight: 0,
    },
    offsetRight: {
      marginRight: 7,
    },
  }));
};

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    gap: 20,
    paddingTop: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        backgroundColor: theme.palette.background,
        paddingTop: 30,
        paddingHorizontal: 30,
        paddingBottom: 20,
        ...theme.bar(10),
      },
    }),
  },
}));

const Header = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        justifyContent: 'space-between',
      },
    }),
  },
}));

const HeaderTitle = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 18,
    lineHeight: 22,
    display: 'flex',
    alignItems: 'center',
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    width: 5,
  },
}));
