import type {CurrencyCode, Millisecond, RateValue} from '@ncwallet-app/core';
import {
  formatExchangeRate,
  useDateFormatting,
  useStrings,
  variance,
} from '@ncwallet-app/core';
import {LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

export type ExchangeDetailsProps = {
  exchangeRate: RateValue<CurrencyCode, CurrencyCode>;
  date: Millisecond;
  fee?: string;
};

export default observer(function ExchangeDetails(props: ExchangeDetailsProps) {
  const {exchangeRate, fee} = props;
  const strings = useStrings();
  const {formatDate} = useDateFormatting();

  return (
    <DetailsContainerView>
      <DetailsRow>
        <Label>{strings['exchangeComfirmScreen.exchangeRate']}:</Label>
        <Value>{formatExchangeRate(exchangeRate)}</Value>
      </DetailsRow>
      <Divider />
      <DetailsRow>
        <Label>{strings['exchangeComfirmScreen.date']}:</Label>
        <Value>{formatDate(props.date)}</Value>
      </DetailsRow>
      <Divider />
      <DetailsRow last>
        <Label>{strings['sendCrypto.confirm.walletFee']}:</Label>
        <Value>{fee ? fee : '0.00'}</Value>
      </DetailsRow>
    </DetailsContainerView>
  );
});

const DetailsContainerView = variance(View)(theme => ({
  root: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 8,
        backgroundColor: theme.palette.uiPrimary,
        borderRightWidth: 1,
        borderLeftWidth: 1,
      },
    }),
  },
}));

const DetailsRow = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingVertical: 37,
      },
    }),
  },
  last: {
    borderBottomWidth: 0,
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textPrimary,
    fontSize: 14,
  },
}));

const Value = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    color: theme.palette.textSecondary,
    fontSize: 14,
  },
}));

const Divider = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
    marginLeft: 15,
    marginRight: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginLeft: 0,
        marginRight: 0,
      },
    }),
  },
}));
