import {fromDate, useRoot} from '@ncwallet-app/core';
import type {
  NotifyAboutSuccessfulExchangeRoute,
  PromptConfirmationForExchangeRoute,
  PromptOtpToExchangeRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import {useCallback, useEffect} from 'react';

import type {ExchangeCryptoConfirmScreenProps} from '../../../screens/ExchangeCryptoConfirmScreen/ExchangeCryptoConfirmScreen';
import useExchangeCryptoConfirmBindingState from './useExchangeCryptoConfirmBindingState';

export type PromptConfirmationForExchangeContainerProps = {
  params: RouteParams<PromptConfirmationForExchangeRoute>;
  setParams: (
    params: Partial<RouteParams<PromptConfirmationForExchangeRoute>>,
  ) => void;
  goBack: () => void;
} & RouteTransitionMap<
  PromptOtpToExchangeRoute | NotifyAboutSuccessfulExchangeRoute
>;
export const usePromptConfirmationForExchangeContainer = (
  props: PromptConfirmationForExchangeContainerProps,
): ExchangeCryptoConfirmScreenProps => {
  const {
    setParams,
    params,
    notifyAboutSuccessfulExchange,
    promptOtpToExchange,
  } = props;
  const {
    walletIdTo,
    walletIdFrom,
    token,
    currencyFrom,
    currencyTo,
    exchangeRate,
    valueFrom,
    valueTo,
    date,
    isTargetValueLastChanged,
    errorCode,
  } = params;
  const state = useExchangeCryptoConfirmBindingState();
  const root = useRoot();
  const cryptoFrom = root.currencyStore.getCryptoCurrency(currencyFrom);
  const cryptoTo = root.currencyStore.getCryptoCurrency(currencyTo);

  useEffect(() => {
    if (errorCode) {
      state.handleOutsideError(errorCode);
      setParams({errorCode: undefined});
    }
  }, [errorCode, setParams, state]);

  const exchange = useCallback(async () => {
    if (state.isPending) {
      return;
    }

    const isTwoFaRequired = await root.twoFaHelper.isTwoFaRequiredForExchange();
    if (!isTwoFaRequired.success) {
      return;
    }

    if (isTwoFaRequired.right) {
      promptOtpToExchange(params);
      return;
    }

    const res = await state.exchange(token);
    if (!res.success) {
      return;
    }

    notifyAboutSuccessfulExchange({
      currencyFrom,
      currencyTo,
      valueFrom,
      valueTo,
    });
  }, [
    state,
    root.twoFaHelper,
    token,
    notifyAboutSuccessfulExchange,
    currencyFrom,
    currencyTo,
    valueFrom,
    valueTo,
    promptOtpToExchange,
    params,
  ]);

  const handleRefresh = useCallback(async () => {
    const res = await state.refresh(
      walletIdFrom,
      currencyFrom,
      currencyTo,
      valueFrom,
      valueTo,
      isTargetValueLastChanged,
      walletIdTo,
    );
    if (res?.success) {
      setParams({
        token: res.right.token,
        valueFrom: res.right.from_amount.toString(),
        valueTo: res.right.to_amount.toString(),
        exchangeRate: res.right.rate,
        date: fromDate(new Date()),
      });
    }
  }, [
    currencyFrom,
    currencyTo,
    isTargetValueLastChanged,
    setParams,
    state,
    valueFrom,
    valueTo,
    walletIdFrom,
    walletIdTo,
  ]);

  return {
    onRefresh: handleRefresh,
    buttonKind: state.isRateChangedError ? 'refresh' : 'edit',
    currencyFrom,
    valueFrom,
    currencyTo,
    valueTo,
    date,
    exchangeRate,
    onEdit: props.goBack,
    onSubmit: exchange,
    error: state.errorDetails?.summary,
    cryptoFrom,
    cryptoTo,
  };
};
