import {useTheme, variance} from '@ncwallet-app/core/src';
import {useFocusInputOnNavigationFocus} from '@ncwallet-app/navigation/src/Navigation/hooks';
import {observer} from 'mobx-react-lite';
import React, {useRef, useState} from 'react';
import {Text, TextInput, View} from 'react-native';

import {HelpSvg} from '../../assets/svg/colorless';
import {LG_BREAKPOINT} from '../grid';
import {TouchableOpacity} from './TouchableOpacity';

export type VerificationCodeInputProps = {
  inputRef?: React.RefObject<TextInput>;
  maxLength: 6;
  getPlaceholder?: () => string | undefined;
  getValue: () => string;
  setValue: (value: string) => void;
  getError: () => string | undefined;
  onExternalLinkPress?: () => void;
};

export const VerificationCodeInput = observer(
  (props: VerificationCodeInputProps) => {
    const {
      inputRef,
      maxLength,
      getPlaceholder,
      getValue,
      setValue,
      getError,
      onExternalLinkPress,
    } = props;
    const theme = useTheme();
    const nativeRef = useRef<TextInput>(null);
    const error = getError();
    const [caretPosition, setCaretPosition] = useState(0);
    useFocusInputOnNavigationFocus(inputRef || nativeRef);
    return (
      <>
        <CodeInputContainer>
          <CodeInput
            ref={inputRef || nativeRef}
            onChangeText={setValue}
            value={getValue()}
            placeholderTextColor={theme.palette.textAdditional3}
            maxLength={maxLength}
            selectionColor={'transparent'}
            keyboardType="numeric"
            inputMode="decimal"
            autoFocus={true}
            testID="2fa-input"
            onSelectionChange={event => {
              setCaretPosition(event.nativeEvent.selection.start);
            }}
          />
          <NumberRow pointerEvents={'box-none'}>
            {getPlaceholder
              ? getPlaceholder()
                  ?.split('')
                  .map((_, idx) => {
                    const inputValue = getValue();

                    if (!inputValue[idx]) {
                      return (
                        <InputNumberHolder pointerEvents={'none'} key={idx}>
                          <InputNumber>0</InputNumber>
                          <InputNumberDecorator
                            isVisible={idx === caretPosition}
                          />
                        </InputNumberHolder>
                      );
                    }

                    return (
                      <InputNumberHolder pointerEvents={'none'} key={idx}>
                        <InputNumber filled>{inputValue[idx]}</InputNumber>
                        <InputNumberDecorator
                          isVisible={caretPosition === idx}
                        />
                      </InputNumberHolder>
                    );
                  })
              : null}
          </NumberRow>
        </CodeInputContainer>
        {error && (
          <ErrorTextTouchable onPress={onExternalLinkPress}>
            <ErrorMessage>{error}</ErrorMessage>
            <HelpIcon />
          </ErrorTextTouchable>
        )}
      </>
    );
  },
);

const HelpIcon = variance(HelpSvg)(
  () => ({
    root: {},
  }),
  theme => ({
    color: theme.palette.textAdditional3,
    width: 18,
    height: 18,
  }),
);

const ErrorTextTouchable = variance(TouchableOpacity)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
  },
  hidden: {
    opacity: 0,
  },
}));

const NumberRow = variance(View)(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

const InputNumberHolder = variance(View)(() => ({
  root: {
    width: 16,
    alignItems: 'center',
  },
}));

const InputNumber = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textAdditional3,
    fontSize: 16,
  },
  filled: {
    color: theme.palette.textAdditional1,
  },
}));

const InputNumberDecorator = variance(View)(theme => ({
  root: {
    width: 13,
    height: 2,
  },
  isVisible: {
    backgroundColor: theme.palette.info,
  },
}));

const CodeInputContainer = variance(View)(theme => ({
  root: {
    position: 'relative',
    width: 210,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        padding: 0,
      },
    }),
  },
}));

const CodeInput = variance(TextInput)(theme => ({
  root: {
    paddingHorizontal: 10,
    ...theme.fontByWeight('700'),
    color: 'transparent',
    fontSize: 16,
    letterSpacing: 20,
    minHeight: 50,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 0,
        paddingVertical: 16,
      },
    }),
  },
}));

const ErrorMessage = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.error,
    textAlign: 'center',
  },
}));
