import {useStrings, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant, LG_BREAKPOINT} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import type {GeneratePayMeLinkBindingState} from '../../Navigation/HomeStack/GeneratePayMeLinkBinding/GeneratePayMeLinkBindingState';
import LinkAmountInput from './LinkAmountInput';
import LinkNameInput from './LinkNameInput';

export type GeneratePayMeLinkBodyProps = {
  shareShown: boolean;
  onShare: (data: string) => void;
  generateLink: () => void;
  onSaveName: () => void;
  link: GeneratePayMeLinkBindingState['link'];
  amount: GeneratePayMeLinkBindingState['amount'];
  currency: GeneratePayMeLinkBindingState['currencyCode'];
  cryptoCurrency: GeneratePayMeLinkBindingState['currency'];
  error: GeneratePayMeLinkBindingState['error'];
  savedLink: GeneratePayMeLinkBindingState['savedLink'];
  setAmount: GeneratePayMeLinkBindingState['setAmount'];
  setLink: GeneratePayMeLinkBindingState['setLink'];
  isAvailableEdit: GeneratePayMeLinkBindingState['isAvailableEdit'];
  onMakeAvailableEdit: GeneratePayMeLinkBindingState['onMakeAvailableEdit'];
  onBackPress?: () => void;
};

export default observer(function GeneratePayMeLinkBody(
  props: GeneratePayMeLinkBodyProps,
) {
  const {
    shareShown,
    onShare,
    generateLink,
    onSaveName,
    onMakeAvailableEdit,
    amount,
    cryptoCurrency,
    currency,
    link,
    error,
    savedLink,
    setAmount,
    setLink,
    isAvailableEdit,
  } = props;
  const strings = useStrings();

  return (
    <Container>
      <LinkNameInput
        link={link}
        generateLink={generateLink}
        onChangeName={setLink}
        savedLink={savedLink}
        error={error}
        disabled={!isAvailableEdit}
        onShare={onShare}
        shareShown={shareShown}
      />
      <LinkAmountInput
        fractionDigits={cryptoCurrency?.decimals}
        amount={amount}
        currency={currency}
        onChangeAmount={setAmount}
        onMakeAvailableEdit={onMakeAvailableEdit}
      />

      <SaveButton
        title={
          isAvailableEdit
            ? strings['generatePayMeLink.button']
            : strings['editPayMeLink.button']
        }
        onPress={isAvailableEdit ? onSaveName : onMakeAvailableEdit}
        variant={ButtonVariant.Primary}
      />
    </Container>
  );
});

const Container = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingTop: 20,
    gap: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
        paddingHorizontal: 0,
        paddingTop: 0,
      },
    }),
  },
}));

const SaveButton = variance(Button)(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxWidth: 345,
      },
    }),
  },
}));
