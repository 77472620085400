import {variance} from '@ncwallet-app/core';
import type {DetailsTransactionFields} from '@ncwallet-app/core/src/NCWalletServer/WalletsTransactions';
import {LG_BREAKPOINT, useIsDimensions} from '@ncwallet-app/ui';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ScrollView} from 'react-native-gesture-handler';

import {DetailsComponentMap} from './DetailsField';
import type {
  HistoryBlockchainNetworkDetail,
  HistoryDetailItem,
  HistoryExchangeDetail,
  HistoryIncomingDetail,
} from './HistoryDetailItem';

export type OperationDetails = Pick<
  HistoryDetailItem,
  | 'kind'
  | 'cryptoCode'
  | 'address'
  | 'walletFee'
  | 'minerFee'
  | 'comment'
  | 'txId'
  | 'infoCurrency'
  | 'cryptoFractionDigits'
> &
  HistoryExchangeDetail &
  HistoryIncomingDetail &
  HistoryBlockchainNetworkDetail;

export type DetailsProps = OperationDetails & {
  onCopy: (data: string) => void;
  showFields: DetailsTransactionFields[];
  isLast?: boolean;
  isInternal?: boolean;
};

export default observer(function Details(props: DetailsProps) {
  const isLg = useIsDimensions('lg');

  return (
    <DetailsView borderless={isLg}>
      {props.showFields.map((field, idx) => {
        const Component = DetailsComponentMap[field];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!Component) {
          return <></>;
        }

        return (
          <Component
            key={field}
            {...props}
            isLast={idx === 0}
            isInternal={props.isInternal}
          />
        );
      })}
    </DetailsView>
  );
});

const DetailsView = variance(ScrollView)(theme => ({
  root: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    borderTopWidth: 1,
    borderTopColor: theme.palette.uiSecondary,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.uiSecondary,
    backgroundColor: theme.palette.uiPrimary,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        maxHeight: 265,
        paddingHorizontal: 0,
        paddingVertical: 0,
      },
    }),
  },
  borderless: {
    borderBottomWidth: 0,
  },
}));
