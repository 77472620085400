import type {
  CryptoCurrency,
  CryptoCurrencyCode,
  CurrencyCode,
  Millisecond,
  RateValue,
} from '@ncwallet-app/core';
import {
  formatCryptoValue,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui/src';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaScrollView,
  SM_BREAKPOINT,
} from '@ncwallet-app/ui/src';
import {ArrowRightSmall} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {ExchangeErrorText} from '@ncwallet-app/ui/src/components/atoms/exchangeComponents';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import ExchangeCard from './ExchangeCard';
import ExchangeDetails from './ExchangeDetails';

export type ExchangeCryptoConfirmScreenProps = SafeAreaScrollViewProps & {
  cryptoFrom: CryptoCurrency | undefined;
  currencyFrom: CryptoCurrencyCode;
  valueFrom: string;
  currencyTo: CryptoCurrencyCode;
  cryptoTo: CryptoCurrency | undefined;
  valueTo: string;
  exchangeRate: RateValue<CurrencyCode, CurrencyCode>;
  date: Millisecond;
  fee?: string;
  error?: string;

  onRefresh: () => void;
  buttonKind: 'edit' | 'refresh';
  onEdit: () => void;
  onSubmit: () => void;
};

export default observer(function ExchangeCryptoConfirmScreen(
  props: ExchangeCryptoConfirmScreenProps,
) {
  const {
    cryptoFrom,
    currencyFrom,
    valueFrom,
    cryptoTo,
    currencyTo,
    valueTo,
    exchangeRate,
    date,
    fee,
    onEdit,
    onSubmit,
    onRefresh,
    buttonKind,
    ...rest
  } = props;
  const strings = useStrings();
  const theme = useTheme();

  return (
    <SafeAreaScrollView {...rest}>
      <ExchangeInfoView>
        <ExchangeCard
          value={formatCryptoValue(valueFrom, cryptoFrom?.decimals)}
          crypto={currencyFrom}
        />
        <ExchangeIconContainer>
          <ArrowRightSmall
            color={theme.palette.uiSecondary}
            style={{transform: [{rotateZ: '90deg'}]}}
          />
        </ExchangeIconContainer>
        <ExchangeCard
          value={formatCryptoValue(valueTo, cryptoTo?.decimals)}
          label={strings['exchangeCrypto.to.label']}
          crypto={currencyTo}
        />
      </ExchangeInfoView>
      <ExchangeDetails exchangeRate={exchangeRate} date={date} fee={fee} />

      <ButtonGroupView>
        <ExchangeErrorText>{props.error}</ExchangeErrorText>
        <SizedBox />
        <Button
          title={
            buttonKind === 'refresh'
              ? strings['exchangeCrypto.update']
              : strings['exchangeComfirmScreen.edit']
          }
          onPress={buttonKind === 'refresh' ? onRefresh : onEdit}
          variant={ButtonVariant.PrimaryLight}
        />
        <SizedBox />
        <Button
          title={strings['exchangeCrypto.submit']}
          disabled={!isNil(props.error)}
          onPress={onSubmit}
          variant={ButtonVariant.Highlighted}
          color={ButtonColor.Secondary}
        />
      </ButtonGroupView>
    </SafeAreaScrollView>
  );
});

const ExchangeInfoView = variance(View)(theme => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    paddingHorizontal: 15,
    paddingVertical: 20,
    ...theme.mediaQuery({
      [SM_BREAKPOINT]: {
        paddingHorizontal: 20,
      },
    }),
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));

const ButtonGroupView = variance(View)(theme => ({
  root: {
    marginTop: 'auto',
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
      },
    }),
  },
}));

const ExchangeIconContainer = variance(View)(() => ({
  root: {
    marginVertical: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
