import type {Millisecond} from '@ncwallet-app/core';
import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core';
import type {SafeAreaScrollViewProps} from '@ncwallet-app/ui';
import {
  BaseSkeleton,
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
  PressableNativeFeedback,
  SafeAreaScrollView,
  useIsDimensions,
} from '@ncwallet-app/ui';
import {
  ReceiptErrorSvg,
  ReceiptSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import CopySvg from '@ncwallet-app/ui/src/assets/svg/colorless/copy.svg';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';

export type CryptoSuccessScreenProps = SafeAreaScrollViewProps & {
  estimatedDuration?: Millisecond;
  title?: string;
  onSubmit: () => void;
  paymentValue?: string;
  paymentText?: string;
  buttonTitle?: string;
  addressTo?: string;
  error?: string;
  onBack: () => void;
  goToSupport: () => void;
  onCopy?: (address: string) => void;
};

export default observer(function CryptoSuccessScreen(
  props: CryptoSuccessScreenProps,
) {
  const {
    onSubmit,
    title,
    paymentValue,
    error,
    addressTo,
    paymentText,
    onCopy,
    ...rest
  } = props;
  const strings = useStrings();
  const theme = useTheme();
  const isLg = useIsDimensions('lg');
  const offsetPaddingBottom = {paddingBottom: 0};
  return (
    <Root
      style={!isLg && styles.root}
      contentContainerStyle={isLg && offsetPaddingBottom}
      {...rest}>
      <Inner>
        {isLg && title && <CryptoSuccessTitle>{title}</CryptoSuccessTitle>}
        <ContainerView>
          <SuccessLogoView>
            {error ? (
              <ErrorLogo
                color={theme.palette.uiPrimary}
                secondColor={theme.palette.uiAdditional1}
              />
            ) : (
              <SuccessLogo
                color={theme.palette.uiPrimary}
                secondColor={theme.palette.uiAdditional1}
              />
            )}
          </SuccessLogoView>
          <PaymentInfoView testID="send-success-text">
            <PaymentCryptoValue error={!!error}>
              {error ? (
                strings['sendCrypto.error.title']
              ) : (
                <>
                  {paymentValue ? (
                    paymentValue
                  ) : (
                    <BaseSkeleton height={20} width={200} />
                  )}
                </>
              )}
            </PaymentCryptoValue>
            {error ? (
              strings['sendCrypto.error.description']
            ) : (
              <>
                <PaymentInfo testID="exchange-success-text">
                  {paymentText}
                </PaymentInfo>
                {addressTo && (
                  <PaymentAddressText address={addressTo} onCopy={onCopy} />
                )}
              </>
            )}
          </PaymentInfoView>
          {props.estimatedDuration && (
            <SuccessMessage>
              {strings['sendCrypto.success.receiveTime']}
            </SuccessMessage>
          )}
        </ContainerView>
        <ContainerBottomView error={!!error}>
          {error ? (
            <>
              <ErrorButton
                title={'Go back'}
                onPress={props.onBack}
                variant={ButtonVariant.Highlighted}
                color={ButtonColor.Secondary}
                first
              />
              <ErrorButton
                title={'Contact support'}
                onPress={props.goToSupport}
                variant={ButtonVariant.PrimaryLight}
                color={ButtonColor.Secondary}
              />
            </>
          ) : (
            <Button
              title={strings['sendCrypto.success.submit']}
              onPress={onSubmit}
              variant={ButtonVariant.Highlighted}
              color={ButtonColor.Secondary}
            />
          )}
        </ContainerBottomView>
      </Inner>
    </Root>
  );
});

const styles = StyleSheet.create({
  root: {
    ...Platform.select({
      web: {
        paddingBottom: 15,
      },
    }),
  },
});

const PaymentAddressText = observer(
  ({
    address,
    onCopy,
  }: {
    address: string;
    onCopy?: (address: string) => void;
  }) => {
    const theme = useTheme();

    return (
      <PaymentInfoAddress>
        <PaymentAddress>{address}</PaymentAddress>
        {onCopy && (
          <AddressCopy
            onPress={() => {
              onCopy(address);
            }}>
            <CopyIcon color={theme.palette.info} />
          </AddressCopy>
        )}
      </PaymentInfoAddress>
    );
  },
);

const CopyIcon = sized(CopySvg, 14);

const ErrorButton = variance(Button)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
  first: {
    marginBottom: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginBottom: 0,
        marginRight: 20,
      },
    }),
  },
}));

const CryptoSuccessTitle = variance(Text)(theme => ({
  root: {
    paddingTop: 30,
    ...theme.fontByWeight('700'),
    fontSize: 18,
    textAlign: 'center',
    color: theme.palette.textMain,
  },
}));

const ContainerView = variance(View)(() => ({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SuccessLogoView = variance(View)(theme => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 110,
    height: 110,
    borderRadius: 110,
    backgroundColor: theme.palette.info,
    marginBottom: 18,
  },
}));

const SuccessLogo = variance(ReceiptSvg)(() => ({
  root: {
    position: 'relative',
    top: 20,
  },
}));

const ErrorLogo = variance(ReceiptErrorSvg)(() => ({
  root: {
    position: 'relative',
    top: 20,
  },
}));

const PaymentInfoView = variance(View)(() => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 30,
    paddingHorizontal: 15,
  },
}));

const PaymentCryptoValue = variance(Text)(theme => ({
  root: {
    marginBottom: 5,
    ...theme.fontByWeight('700'),
    color: theme.palette.primary,
    fontSize: 24,
    textAlign: 'center',
  },

  error: {
    color: theme.palette.error,
    paddingHorizontal: 15,
  },
}));

const PaymentInfo = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textSecondary,
    maxWidth: 200,
    textAlign: 'center',
    lineHeight: 20,
    marginBottom: 5,
  },
}));

const SuccessMessage = variance(Text)(theme => ({
  root: {
    maxWidth: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    ...theme.fontByWeight('500'),
    color: theme.palette.textSecondary,
    textAlign: 'center',
  },
}));

const ContainerBottomView = variance(View)(theme => ({
  root: {
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: 345,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 15,
      },
    }),
  },

  error: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: 0,
      },
    }),
  },
}));

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        backgroundColor: theme.palette.background,
        paddingHorizontal: 30,
        paddingTop: 0,
        paddingBottom: 15,
      },
    }),
  },
}));

const PaymentInfoAddress = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    padding: 15,
    borderRadius: 6,
    gap: 15,
    alignItems: 'center',
    backgroundColor: theme.palette.uiPrimary,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const PaymentAddress = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.textPrimary,
    wordBreak: 'break-all',
  },
}));

const AddressCopy = variance(PressableNativeFeedback)(() => ({
  root: {
    padding: 3,
  },
}));

const Root = variance(SafeAreaScrollView)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        ...theme.bar(10),
      },
    }),
  },
}));
