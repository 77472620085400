import {sized, useStrings, useTheme, variance} from '@ncwallet-app/core/src';
import {ButtonVariant, useIsDimensions} from '@ncwallet-app/ui/src';
import {
  ArrowDownWideSvg,
  CopySvg,
  ShareSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {
  ActionButton,
  Divider,
  WalletAddressAction,
  WalletAddressActions,
  WalletAddressLabel,
  WalletAddressLabelPress,
  WalletAddressValue,
  WalletAddressValueContainer,
} from '../../shared/WalletAddres';

type WalletInfoProps = {
  label: string;
  info: string;
  onCopy: (data: string) => void;
  onShare: (data: string) => void;
  goToAddresses: () => void;
  shareShown: boolean;
  addressType?: string;
};

export const WalletInfo = observer((props: WalletInfoProps) => {
  const theme = useTheme();
  const actionsWidth = props.shareShown ? 101 : 51;
  const firstHalfAddress = props.info.slice(
    0,
    Math.floor(props.info.length / 2) + 5,
  );
  const secondHalfAddress = props.info.slice(
    Math.floor(props.info.length / 2) + 5,
    props.info.length,
  );

  const isLg = useIsDimensions('lg');
  const strings = useStrings();
  if (!isLg) {
    return (
      <View>
        <WalletAddressHeader>
          <WalletAddressLabelPress onPress={props.goToAddresses}>
            <WalletAddressLabel>
              {props.label} {props.addressType && `(${props.addressType})`}
            </WalletAddressLabel>
            <ArrowDownIcon color={theme.palette.uiMain} />
          </WalletAddressLabelPress>
        </WalletAddressHeader>
        <WalletAddressSmValue>{props.info}</WalletAddressSmValue>
        <Buttons>
          {props.shareShown && (
            <ActionButton
              onPress={() => {
                props.onShare(props.info);
              }}
              title={strings['receiveCryptoScreen.share']}
              variant={ButtonVariant.PrimaryLight}
              Icon={ShareSvg}
              offsetRight
            />
          )}
          <ActionButton
            onPress={() => {
              props.onCopy(props.info);
            }}
            title={strings['receiveCryptoScreen.copy']}
            variant={ButtonVariant.PrimaryLight}
            Icon={CopySvg}
          />
        </Buttons>
      </View>
    );
  }

  return (
    <View>
      <WalletAddressLabelPress onPress={props.goToAddresses}>
        <WalletAddressLabel>
          {props.label} {props.addressType && `(${props.addressType})`}
        </WalletAddressLabel>
        <ArrowDownIcon color={theme.palette.uiMain} />
      </WalletAddressLabelPress>
      <WalletAddressContainer>
        <>
          <WalletAddressValueContainer>
            <WalletAddressValue numberOfLines={1} ellipsizeMode="middle">
              <Text numberOfLines={1} ellipsizeMode="tail">
                {firstHalfAddress}
              </Text>
              <Text>{secondHalfAddress}</Text>
            </WalletAddressValue>
          </WalletAddressValueContainer>

          <WalletAddressActions style={{maxWidth: actionsWidth}}>
            <Divider />
            <WalletAddressAction
              onPress={() => {
                props.onCopy(props.info);
              }}>
              <CopySvg color={theme.palette.info} />
            </WalletAddressAction>
            {props.shareShown && (
              <>
                <Divider />
                <WalletAddressAction
                  onPress={() => {
                    props.onShare(props.info);
                  }}>
                  <ShareSvg color={theme.palette.info} />
                </WalletAddressAction>
              </>
            )}
          </WalletAddressActions>
        </>
      </WalletAddressContainer>
    </View>
  );
});

const ArrowDownIcon = sized(ArrowDownWideSvg, 12, 12);

const WalletAddressHeader = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
}));

export const WalletAddressContainer = variance(View)(theme => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    backgroundColor: theme.palette.uiPrimary,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
  affiliate: {
    marginTop: 10,
  },
}));

const WalletAddressSmValue = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    padding: 15,
    backgroundColor: theme.palette.uiPrimary,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
    fontSize: 16,
    lineHeight: 19,
    color: theme.palette.textSecondary,
    borderRadius: 6,
  },
}));

const Buttons = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    marginTop: 15,
  },
}));
