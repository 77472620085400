import {
  formatCryptoValue,
  useDateFormatting,
  variance,
} from '@ncwallet-app/core';
import type {WalletTransactions} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import {TouchableOpacity, TransactionIcon} from '@ncwallet-app/ui';
import {BigNumber} from 'bignumber.js';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text} from 'react-native';

import {useProceedTransaction} from './useProceedTransaction';

export type WalletSlideTransactionBodyProps = {
  transaction: WalletTransactions.AggregationItem;
  onPress: () => void;
};

export default observer(function WalletSlideTransactionBody(
  props: WalletSlideTransactionBodyProps,
) {
  const {transaction, onPress} = props;

  return (
    <TransactionTouchable onPress={onPress}>
      <TransactionBodyView transaction={transaction} />
    </TransactionTouchable>
  );
});

const TransactionBodyView = observer(
  (props: {transaction: WalletTransactions.AggregationItem}) => {
    const {transaction} = props;
    const {formatDateTimeWithoutYear} = useDateFormatting();
    const transactionProceeded = useProceedTransaction(transaction);

    return (
      <>
        {transaction.front_info && (
          <TransactionIcon kind={transaction.front_info.frontType} size={22} />
        )}
        <TransactionDate>
          {formatDateTimeWithoutYear(transactionProceeded.date)}
        </TransactionDate>
        <TransactionCryptoValue
          income={BigNumber(transactionProceeded.cryptoValue).isPositive()}>
          {BigNumber(transactionProceeded.cryptoValue).isPositive() ? '+' : ''}
          {formatCryptoValue(
            transactionProceeded.cryptoValue,
            transactionProceeded.decimals,
          )}{' '}
          {transactionProceeded.cryptoCode}
        </TransactionCryptoValue>
      </>
    );
  },
);

const TransactionTouchable = variance(TouchableOpacity)(
  () => ({
    root: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    container: {
      width: '100%',
      height: '100%',
    },
  }),
  (theme, styles) => ({
    containerStyle: styles.container,
  }),
);

const TransactionDate = variance(Text)(theme => ({
  root: {
    marginLeft: 10,
    ...theme.fontByWeight('500'),
    fontSize: 12,
    color: theme.palette.textAdditional1,
  },
}));

const TransactionCryptoValue = variance(Text)(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 5,
    ...theme.fontByWeight('700'),
    fontSize: 12,
    color: theme.palette.error,
  },
  income: {
    color: theme.palette.success,
  },
}));
