import {useRoot, variance} from '@ncwallet-app/core';
import {Button, ButtonVariant} from '@ncwallet-app/ui';
import {
  WaitingDarkSvg,
  WaitingSvg,
} from '@ncwallet-app/ui/src/assets/svg/colored/';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';
import {Text, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import type {FinalBuyMessageProps} from './FinalBuyMessage';

export type FinalMessageContentProps = FinalBuyMessageProps;

export default observer(function FinalMessageContent(
  props: FinalMessageContentProps,
) {
  const {onConfirm} = props;
  const {appearance, layoutHelperState, localization} = useRoot();
  const {bottom} = useSafeAreaInsets();
  const isLarge = layoutHelperState.isLarge;

  const containerStyle = useMemo(() => {
    return isLarge ? {} : {paddingBottom: bottom};
  }, [bottom, isLarge]);

  return (
    <Container style={containerStyle}>
      <BodyWrapper>
        {appearance.isDark ? <WaitingDarkSvg /> : <WaitingSvg />}
        <Title>
          {localization.getTranslation('buyCrypto.backMessage.title')}
        </Title>
        <Description>
          {localization.getTranslation('buyCrypto.backMessage.description')}
        </Description>
      </BodyWrapper>
      <ButtonWrapper>
        <Button
          title={localization.getTranslation('buyCrypto.backMessage.button')}
          variant={ButtonVariant.Primary}
          onPress={onConfirm}
        />
      </ButtonWrapper>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    paddingHorizontal: 15,
  },
}));

const BodyWrapper = variance(View)(() => ({
  root: {
    gap: 20,
    paddingVertical: 20,
    alignItems: 'center',
  },
}));

export const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 26,
    lineHeight: 36,
    textAlign: 'center',
    color: theme.palette.textPrimary,
  },
}));

export const Description = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('400'),
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center',
    color: theme.palette.textAdditional1,
  },
}));

const ButtonWrapper = variance(View)(() => ({
  root: {
    gap: 15,
    paddingVertical: 20,
  },
}));
