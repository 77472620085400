/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  TransactionFilterKind,
  useStrings,
  useStyles,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  Button,
  ButtonVariant,
  LG_BREAKPOINT,
  SafeAreaInset,
  SafeAreaScrollView,
  Spinner,
  useIsDimensions,
} from '@ncwallet-app/ui/src';
import {
  ArrowLeftWide,
  FileDownloadSvg,
  SupportSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Text, View} from 'react-native';

import {EXTRA_BOTTOM_OFFSET} from '../constants';
import HistorySkeletonListItem from '../HistoryScreen/HistoryList/HistorySkeletonListItem';
import Details from './Details';
import DetailsHeader from './DetailsHeader';
import type {
  HistoryBlockchainNetworkDetail,
  HistoryDetailItem,
  HistoryExchangeDetail,
  HistoryIncomingDetail,
} from './HistoryDetailItem';
import HistoryDetailsSkeleton from './HistoryDetailsSkeleton';

export type HistoryDetailsScreenProps = {
  transaction?: HistoryDetailItem &
    HistoryExchangeDetail &
    HistoryIncomingDetail &
    HistoryBlockchainNetworkDetail;
  onCopy: (data: string) => void;
  onRepeat: () => void;
  infoNetwork?: string;
  onContactSupport: () => void;
  onBack: () => void;

  onTransactionReport?: () => Promise<void>;
  isLoading?: boolean;
  error?: string | null;
};

export default observer(function HistoryDetailsScreen(
  props: HistoryDetailsScreenProps,
) {
  const isLg = useIsDimensions('lg');
  const theme = useTheme();
  const strings = useStrings();
  const styles = useLgStyles();
  const {
    transaction,
    onCopy,
    onContactSupport,
    onBack,
    onTransactionReport,
    isLoading,
    error,
  } = props;
  const historyItem = transaction;

  return (
    <DetailsScreenRoot
      style={styles.container}
      contentContainerStyle={styles.inner}
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}>
      {isLg && (
        <LgBackButtonWrapper>
          <BackButton
            title={strings['screenTitle.historyDetails']}
            onPress={onBack}
            Icon={ArrowLeftWide}
            iconCustomColor={theme.palette.uiAdditional1}
          />
        </LgBackButtonWrapper>
      )}
      <DetailsScreenWrapper>
        {historyItem ? (
          <>
            <DetailsHeader
              cryptoValue={historyItem.cryptoValue}
              cryptoCode={historyItem.cryptoCode}
              cryptoName={historyItem.cryptoName}
              cryptoFractionDigits={historyItem.fromCrypto?.decimals}
              date={historyItem.date}
              kind={historyItem.kind}
              uri={historyItem.uri}
              status={historyItem.status}
            />

            {historyItem.kind === TransactionFilterKind.Exchange &&
              historyItem.amountFrom && (
                <>
                  <Divider />
                  <DetailsHeader
                    cryptoValue={historyItem.amountFrom}
                    cryptoCode={historyItem.infoFromCurrency!}
                    cryptoName={historyItem.infoFromCurrency}
                    cryptoFractionDigits={historyItem.toCrypto?.decimals}
                    date={historyItem.date}
                    kind={historyItem.kind}
                    uri={historyItem.uri}
                    status={historyItem.status}
                  />
                </>
              )}
          </>
        ) : (
          <HistorySkeletonListItem />
        )}
        {historyItem ? (
          <Details
            kind={historyItem.kind}
            cryptoCode={historyItem.cryptoCode}
            walletFee={historyItem.walletFee}
            minerFee={historyItem.minerFee}
            cryptoFractionDigits={historyItem.cryptoFractionDigits}
            rate={historyItem.rate}
            fromCurrency={historyItem.fromCurrency}
            toCurrency={historyItem.toCurrency}
            onCopy={onCopy}
            showFields={historyItem.showFields || []}
            infoNetwork={props.infoNetwork}
            infoFromCurrency={historyItem.infoFromCurrency}
            infoToCurrency={historyItem.infoToCurrency}
            infoCurrency={historyItem.infoCurrency}
            amountFrom={historyItem.amountFrom}
            amountTo={historyItem.amountTo}
            address={historyItem.address}
            txId={historyItem.txId}
            fromAddress={historyItem.fromAddress}
            comment={historyItem.comment}
            blockchainNetwork={historyItem.blockchainNetwork}
            isInternal={historyItem.infoNetwork?.includes('internal')}
          />
        ) : (
          <HistoryDetailsSkeleton />
        )}
      </DetailsScreenWrapper>
      <DetailsFooter>
        {error && <ErrorText>{error}</ErrorText>}
        <DetailsControls>
          {/* repeat button is deleted in 0f38a4e66de587bc15fff4ae93720d533eb75fc6 commit, revert it to get it back*/}
          <DetailsButton
            Icon={SupportSvg}
            title={strings['historyDetailsScreen.contactSupport']}
            variant={ButtonVariant.PrimaryLight}
            onPress={onContactSupport}
          />
          {isLoading ? (
            <SpinnerWrapper>
              <Spinner size={52} />
            </SpinnerWrapper>
          ) : (
            <DetailsButton
              Icon={FileDownloadSvg}
              title={strings['createStatement.button']}
              variant={ButtonVariant.Primary}
              onPress={onTransactionReport}
            />
          )}
        </DetailsControls>
      </DetailsFooter>
    </DetailsScreenRoot>
  );
});

const useLgStyles = () =>
  useStyles(theme => ({
    container: {
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          overflow: 'visible',
          backgroundColor: theme.palette.uiPrimary,
        },
      }),
    },
    inner: {
      ...theme.mediaQuery({
        [LG_BREAKPOINT]: {
          borderRadius: 10,
          backgroundColor: theme.palette.background,
          ...theme.bar(10),
        },
      }),
    },
  }));

const SpinnerWrapper = variance(View)(() => ({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 20,
  },
}));

const DetailsScreenRoot = variance(SafeAreaScrollView)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        borderRadius: 10,
        shadowColor: theme.palette.blackout,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.15,
        shadowRadius: 10,
      },
    }),
  },
}));

const DetailsScreenWrapper = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        marginHorizontal: 30,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: theme.palette.uiSecondary,
        overflow: 'hidden',
      },
    }),
  },
}));
const DetailsControls = variance(View)(theme => ({
  root: {
    marginTop: 'auto',
    gap: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row-reverse',
      },
    }),
  },
}));

const ErrorText = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    color: theme.palette.error,
    fontSize: 14,
    textAlign: 'center',
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        textAlign: 'left',
      },
    }),
  },
}));

const DetailsFooter = variance(View)(() => ({
  root: {
    marginTop: 'auto',
    paddingVertical: 15,
    paddingHorizontal: 30,
    gap: 20,
  },
}));

const DetailsButton = variance(Button)(() => ({
  root: {
    flex: 1,
  },
}));

const LgBackButtonWrapper = variance(View)(() => ({
  root: {
    marginBottom: 15,
    paddingTop: 30,
    paddingHorizontal: 30,
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Divider = variance(View)(theme => ({
  root: {
    height: 1,
    backgroundColor: theme.palette.uiSecondary,
  },
}));
