import type {
  CryptoAddress,
  CryptoCurrencyCode,
  WalletId,
} from '@ncwallet-app/core';
import type {DepositType} from '@ncwallet-app/core/src/CommonNavigationScheme';
import {type AddressNetwork} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import type {ShowQrToReceiveCryptoContainerProps} from '../../../CommonNavigationContainers/hooks/useReceiveCryptoScreenProps';
import useReceiveCryptoScreenProps from '../../../CommonNavigationContainers/hooks/useReceiveCryptoScreenProps';
import {useBuyLinkBindingState} from '../../../Navigation/HomeStack/BuyLinkBinding/useBuyLinkBindingState';
import {ReceiveCryptoScreen} from '../../../screens/ReceiveCryptoScreen';
import type {SmallHomeStackBindingProps} from '../SmallHomeStackBindingProps';

export type ShowQrToReceiveCryptoBindingProps =
  SmallHomeStackBindingProps<'ShowQrToReceiveCrypto'>;

export default observer(function ShowQrToReceiveCryptoBinding(
  props: ShowQrToReceiveCryptoBindingProps,
) {
  const {navigation, route} = props;

  const bindingProps = useMemo<ShowQrToReceiveCryptoContainerProps>(
    () => ({
      params: route.params,
      setNewAddressParams: (address, network) => {
        navigation.setParams({address, addressNetwork: network});
      },
      listInputAddresses: params => {
        navigation.navigate('ListInputAddresses', params);
      },
      promptInputNetwork: params => {
        navigation.navigate('PromptInputNetwork', params);
      },
      promptAddressFormat: params => {
        navigation.navigate('PromptAddressFormat', params);
      },
      generatePayMeLink: params => {
        navigation.navigate('GeneratePayMeLink', params);
      },
      showQr: () => {
        navigation.navigate('ShowQr', route.params);
      },
      promptReceiveNetwork: params => {
        navigation.navigate('PromptReceiveNetwork', params);
      },
      promptReceiveCurrency: params => {
        navigation.navigate('PromptCryptoToReceive', params);
      },
      setParams: (params: {
        walletId: WalletId;
        address: CryptoAddress;
        depositType?: DepositType;
        addressCurrency: CryptoCurrencyCode;
        addressNetwork: AddressNetwork;
      }) => {
        navigation.setParams(params);
      },
      goToRoot: () => {
        navigation.navigate('Root');
      },
    }),
    [route.params, navigation],
  );
  const screenProps = useReceiveCryptoScreenProps(bindingProps);
  const buyState = useBuyLinkBindingState({
    currency: screenProps.networkCurrency,
    address: route.params.address,
    network: route.params.addressNetwork,
  });
  return (
    <ReceiveCryptoScreen
      {...screenProps}
      buyState={buyState}
      enabledWebBottomOffset
      keyboardAvoiding={true}
    />
  );
});
