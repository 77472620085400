/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  CommonErrorCode,
  isCommonErrorResponse,
  isNetworkChangePossible,
  isNetworkShown,
  useRoot,
  useStrings,
} from '@ncwallet-app/core';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import {useGetIsReadyToMakeRequests} from '@ncwallet-app/core/src/AppStateHelper';
import type {
  PromptConfirmationToSendCryptoRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {ErrorDetails} from '@ncwallet-app/core/src/ErrorParser';
import {SafeAreaInset} from '@ncwallet-app/ui';
import {BigNumber} from 'bignumber.js';
import {autorun, reaction, runInAction} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {
  useCryptoCurrencies,
  useNavigationGetIsFocused,
} from '../../Navigation/hooks';
import {EXTRA_BOTTOM_OFFSET} from '../../screens/constants';
import {SendConfirmScreen} from '../../screens/SendConfirmScreen';

export type PromptConfirmationToSendCryptoContainerProps = {
  params: RouteParams<PromptConfirmationToSendCryptoRoute>;
  setParams: (
    params: Partial<RouteParams<PromptConfirmationToSendCryptoRoute>>,
  ) => void;
  promptTwoFaEnabling: () => void;
  promptOtpToSendCrypto: (withdrawId: string) => void;
  onAddressEdit: () => void;
  cameFromChangeNetwork: boolean;
  onMinerFeeEdit: () => void;
  onAmountEdit: () => void;
  onNetworkEdit: () => void;
  onCommentEdit: () => void;
  onBack: () => void;
};

export default observer(function PromptConfirmationToSendCryptoContainer(
  props: PromptConfirmationToSendCryptoContainerProps,
) {
  const {
    onBack,
    promptTwoFaEnabling,
    promptOtpToSendCrypto,
    onAmountEdit,
    onMinerFeeEdit,
    onNetworkEdit,
    onAddressEdit,
    cameFromChangeNetwork,
    onCommentEdit,
    setParams,
    params,
  } = props;
  const {
    amount,
    addressNetwork,
    addressCurrency,
    addressTo,
    comment,
    walletId,
    currency,
    fee = '0',
    feeChangeDisabled = false,
  } = params;
  const strings = useStrings();
  const {account} = useAccountState();
  const root = useRoot();
  const {getCryptoCurrency, loadCryptoCurrencies, timeoutErrorBox} =
    useCryptoCurrencies();
  const getIsFocused = useNavigationGetIsFocused();
  const getIsReady = useGetIsReadyToMakeRequests();
  const [confirmError, setConfirmError] = useState<ErrorDetails | null>(null);

  useEffect(
    () =>
      autorun(() => {
        if (getIsReady() && getIsFocused()) {
          runInAction(() => {
            void loadCryptoCurrencies();
          });
        } else {
          setConfirmError(null);
        }
      }),
    [getIsFocused, getIsReady, loadCryptoCurrencies],
  );

  const updateEstimatedFee = useCallback(async () => {
    const res = await root.ncWalletJsonRpcClient.call(
      'wallets.withdrawals.estimated_fee',
      {
        currency: addressCurrency,
        network: addressNetwork,
        options: {
          address_to: addressTo,
          amount: amount,
        },
      },
    );
    if (res.success) {
      setParams({fee: res.right.fees[0].fee});
    }
  }, [
    addressNetwork,
    addressTo,
    addressCurrency,
    root.ncWalletJsonRpcClient,
    setParams,
    amount,
  ]);

  const validateNetworkAddress = useCallback(async () => {
    const res = await root.ncWalletJsonRpcClient.call(
      'wallets.addresses.validate',
      {
        address: addressTo,
        currency: addressCurrency,
        network: addressNetwork,
      },
    );

    if (!res.success) {
      return;
    }

    const isValid = res.right.is_valid;
    if (!isValid) {
      setConfirmError({
        code: CommonErrorCode.InvalidAddress,
        summary: strings['sendCrypto.validation.Networkandaddressmismatch'],
      });
      return;
    } else {
      setConfirmError(null);
    }

    await updateEstimatedFee();
  }, [
    strings,
    updateEstimatedFee,
    addressNetwork,
    addressTo,
    addressCurrency,
    root.ncWalletJsonRpcClient,
  ]);

  useEffect(() => {
    if (cameFromChangeNetwork) {
      void validateNetworkAddress();
    }
  }, [cameFromChangeNetwork, validateNetworkAddress]);

  const goToCode = useCallback(async () => {
    if (!account?.tfa) {
      promptTwoFaEnabling();
      return;
    }

    const fee_ = BigNumber(fee);
    const isOkFee = fee_.isFinite() && fee_.isGreaterThan(0);
    const res = await root.ncWalletJsonRpcClient.call(
      'wallets.withdrawals.create',
      {
        amount,
        network: addressNetwork,
        currency: addressCurrency,
        address: addressTo,
        wallet_id: walletId,
        comment,
        fee: isOkFee ? BigNumber(fee).toString() : undefined,
        batch: !isOkFee,
      },
    );

    if (!res.success) {
      const error = root.errorParser.describe(res.left);
      if (
        !isCommonErrorResponse(res.left) ||
        (isCommonErrorResponse(res.left) &&
          [
            CommonErrorCode.WalletInsufficientFunds,
            CommonErrorCode.EstimatedFeeChanged,
          ].includes(res.left.body.code))
      ) {
        root.flashMessage.showMessage({
          title: error.summary,
          variant: 'danger',
        });
      }
      if (
        isCommonErrorResponse(res.left) &&
        res.left.body.code === CommonErrorCode.EstimatedFeeChanged
      ) {
        setParams({fee: res.left.body.data.new_fee});
      }
      if (
        isCommonErrorResponse(res.left) &&
        res.left.body.code === CommonErrorCode.InvalidAddress
      ) {
        setConfirmError(error);
      }
      return;
    }

    promptOtpToSendCrypto(res.right.id);
  }, [
    account,
    fee,
    root,
    amount,
    addressNetwork,
    addressCurrency,
    addressTo,
    walletId,
    comment,
    setParams,
    promptOtpToSendCrypto,
    promptTwoFaEnabling,
  ]);

  const crypto = getCryptoCurrency(currency);
  const output = useMemo(
    () =>
      crypto?.options.currencies_out.find(
        o => o.network === addressNetwork && o.currency === addressCurrency,
      ),
    [crypto?.options.currencies_out, addressNetwork, addressCurrency],
  );
  const totalBalance = useMemo(() => {
    const wallet = root.walletStore.getWalletById(walletId);
    if (wallet) {
      return wallet.total;
    }
  }, [root.walletStore, walletId]);

  const isBlockchainComment =
    crypto?.options.currencies_out.find(c => c.network === addressNetwork)
      ?.allow_comment ?? false;

  useEffect(
    () =>
      reaction(
        () => timeoutErrorBox.get(),
        shouldRequest => {
          if (shouldRequest) {
            timeoutErrorBox.set(false);
            root.rpcTimeoutErrorVisibility.registerAction(async () => {
              await loadCryptoCurrencies();
              await updateEstimatedFee();
            });
          }
        },
      ),
    [
      loadCryptoCurrencies,
      root.rpcTimeoutErrorVisibility,
      timeoutErrorBox,
      updateEstimatedFee,
    ],
  );

  return (
    <SendConfirmScreen
      addressName={output?.name}
      totalBalance={totalBalance!}
      addressNameShown={!!crypto && isNetworkShown(crypto, 'out')}
      networkChangeEnabled={!!crypto && isNetworkChangePossible(crypto, 'out')}
      onBackPress={onBack}
      onNetworkEdit={onNetworkEdit}
      feeChangeDisabled={feeChangeDisabled}
      amount={amount}
      fee={fee}
      addressTo={addressTo}
      crypto={crypto}
      currency={addressCurrency}
      comment={comment}
      confirmError={confirmError}
      onAddressEdit={onAddressEdit}
      onAmountEdit={onAmountEdit}
      onMinerFeeEdit={onMinerFeeEdit}
      onCommentEdit={onCommentEdit}
      onSubmit={goToCode}
      keyboardAvoiding
      insets={SafeAreaInset.BOTTOM}
      extra={{bottom: EXTRA_BOTTOM_OFFSET}}
      isBlockchainComment={isBlockchainComment}
    />
  );
});
