/* eslint-disable @typescript-eslint/no-non-null-assertion,@typescript-eslint/unbound-method */
import type {Millisecond} from '@ncwallet-app/core';
import {fromDate} from '@ncwallet-app/core';
import type {
  FallBackRoute,
  PromptConfirmationForExchangeRoute,
  PromptCryptoToExchangeRoute,
  PromptExchangeReceiptRoute,
  RouteParams,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {RouteTransitionMap} from '@ncwallet-app/core/src/CommonNavigationScheme/CommonState/RouteTransitionMap';
import delayResolve from '@ncwallet-app/core/src/util/delayResolve';
import {isNil, noop} from 'lodash';
import {useCallback, useEffect} from 'react';
import {Platform} from 'react-native';

import useExchangeFormBindingState from './useExchangeFormBindingState';

export type PromptExchangeReceiptContainerProps = {
  params: RouteParams<PromptExchangeReceiptRoute>;
  setParams: (params: Partial<RouteParams<PromptExchangeReceiptRoute>>) => void;
} & RouteTransitionMap<
  | FallBackRoute
  | PromptCryptoToExchangeRoute
  | PromptConfirmationForExchangeRoute
>;

export const usePromptExchangeReceiptContainer = (
  props: PromptExchangeReceiptContainerProps,
) => {
  const {
    params,
    fallBack,
    promptCryptoToExchange,
    promptConfirmationForExchange,
    setParams,
  } = props;

  const state = useExchangeFormBindingState(
    params.walletIdFrom,
    params.walletIdTo,
    params.currencyTo,
    params.value,
    params.isTargetValue,
  );

  useEffect(() => {
    if (state.isExchangeRateLoaded && isNil(state.exchangeRate)) {
      fallBack();
    }
  }, [state.isExchangeRateLoaded, state.exchangeRate, fallBack]);

  const syncParams = useCallback(async () => {
    const isTargetValue = state.isLastChangedFieldTo();
    setParams({
      walletIdFrom: state.walletIdFrom,
      walletIdTo: state.walletIdTo,
      currencyTo: state.currencyTo,
      value: isTargetValue ? state.valueTo : state.valueFrom,
      isTargetValue,
    });

    if (Platform.OS === 'web') {
      // delay needed for url change before redirect on next screen
      const webDelayForUrlSync = 0 as Millisecond;
      return delayResolve(webDelayForUrlSync, noop);
    }
  }, [setParams, state]);

  const goToCurrencyFromSelection = useCallback(async () => {
    await syncParams();
    const isTargetValue = state.isLastChangedFieldTo();
    if (state.walletIdFrom === undefined) {
      return;
    }
    promptCryptoToExchange({
      isSourceRequested: true,
      value: isTargetValue ? state.valueTo : state.valueFrom,
      isTargetValue,
      walletIdFrom: state.walletIdFrom,
      walletIdTo: state.walletIdTo,
    });
  }, [promptCryptoToExchange, state, syncParams]);

  const goToCurrencyToSelection = useCallback(async () => {
    await syncParams();
    const isTargetValue = state.isLastChangedFieldTo();
    if (state.walletIdFrom === undefined) {
      return;
    }
    promptCryptoToExchange({
      isSourceRequested: false,
      walletIdFrom: state.walletIdFrom,
      walletIdTo: state.walletIdTo,
      value: isTargetValue ? state.valueTo : state.valueFrom,
      isTargetValue,
      disabledCrypto: !state.isSwapCurrenciesEnabled()
        ? state.currencyFrom
        : undefined,
    });
  }, [promptCryptoToExchange, state, syncParams]);

  const handleSubmit = useCallback(async () => {
    const canBeSubmitted = await state.submit();
    if (canBeSubmitted) {
      await syncParams();
      promptConfirmationForExchange({
        token: state.lastExchangeToken!,
        currencyFrom: state.currencyFrom!,
        valueFrom: state.valueFrom!.toString(),
        currencyTo: state.currencyTo!,
        valueTo: state.valueTo!.toString(),
        date: fromDate(new Date()),
        exchangeRate: state.exchangeRate!,
        walletIdFrom: state.walletIdFrom!,
        walletIdTo: state.walletIdTo,
        isTargetValueLastChanged: state.isLastChangedFieldTo(),
      });
    }
  }, [promptConfirmationForExchange, state, syncParams]);

  const handleMaxPress = useCallback(() => {
    state.changeValueFrom(state.maxValueFrom);
  }, [state]);

  const handleSwap = useCallback(async () => {
    state.swapCurrencies();
    await syncParams();
  }, [state, syncParams]);

  return {
    currencyFrom: state.currencyFrom,
    currencyTo: state.currencyTo,
    valueFrom: state.valueFrom,
    valueTo: state.valueTo,
    cryptoTo: state.cryptoTo,
    cryptoFrom: state.cryptoFrom,
    maxValueFrom: state.maxValueFrom,
    maxValueTo: state.maxValueTo,
    maxRestrictedByWalletLimit: state.maxRestrictedByWalletLimit,
    exchangeRate: state.exchangeRate,
    error: state.getShownError(),
    currencyCodeError: state.getCurrencyCodeError(),
    hasValueFromError: state.hasValueFromError(),
    hasValueToError: state.hasValueToError(),
    submitEnabled: state.isExchangeEnabled(),
    onValueFromChange: state.changeValueFrom,
    onValueToChange: state.changeValueTo,
    swapEnabled: state.isSwapCurrenciesEnabled(),
    onCurrenciesSwap: handleSwap,
    onMaxValueFromPress: handleMaxPress,
    onMaxValueToPress: handleMaxPress,
    onCurrencyFromPress: goToCurrencyFromSelection,
    onCurrencyToPress: goToCurrencyToSelection,
    onSubmit: handleSubmit,
    isGraphsDataLoaded: state.isGraphsDataLoaded,
    currencyFromToFiatRate: state.currencyFromToFiatRate,
    currencyToToFiatRate: state.currencyToToFiatRate,
    currencyFromRateHistory: state.currencyFromRateHistory,
    currencyToRateHistory: state.currencyToRateHistory,
    currencyFromDiff: state.currencyFromDiff,
    currencyToDiff: state.currencyToDiff,
    baseFiat: state.baseFiat!,
    isLoading: state.isLoading,
    minAmount: state.minAmount,
    errorAction: state.errorAction,
    refreshExchangeRate: state.refreshExchangeRate,
  };
};
