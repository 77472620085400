import type {
  CryptoCurrencyCode,
  CurrencyDescription,
  WalletLimit,
} from '@ncwallet-app/core';
import {useStrings, variance, WalletLimitPeriod} from '@ncwallet-app/core';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import type {Ref} from 'react';
import React from 'react';
import {View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import LimitInfo from '../../../shared/LimitInfo';
import LimitInfoUnset from '../../../shared/LimitInfoUnset';
import {useComputeEndPeriodLimitTime} from '../../../shared/useComputePeriodLimitTime';
import CurrencyLimitSkeleton from './CurrencyLimitSkeleton';

export type CurrencyLimitScreenProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bottomSheetRef?: Ref<any>;
  code: string | undefined;
  currency: CurrencyDescription | undefined;
  onDeletePress: (l: WalletLimit) => void;
  onDeleteWaitingForActivationLimitPress: (l: WalletLimit) => void;
  onCancelDeletePress: (l: WalletLimit) => void;
  onChangePress: (l: WalletLimit) => void;
  onCreatePress: (
    currency: CryptoCurrencyCode,
    period: WalletLimitPeriod,
    perOnePeriodOnly?: boolean,
  ) => void;
  getDailyLimit: () => WalletLimit | undefined;
  getNewDailyLimit: () => WalletLimit | undefined;
  getMonthlyLimit: () => WalletLimit | undefined;
  getNewMonthlyLimit: () => WalletLimit | undefined;
};

export const CurrencyLimitScreen = observer(
  (props: CurrencyLimitScreenProps) => {
    const dailyLimit = props.getDailyLimit();
    const newDailyLimit = props.getNewDailyLimit();
    const monthlyLimit = props.getMonthlyLimit();
    const newMonthlyLimit = props.getNewMonthlyLimit();
    const [dailyRemainingTime, monthlyRemainingTime] =
      useComputeEndPeriodLimitTime();
    const strings = useStrings();

    if ((isNil(dailyLimit) && isNil(monthlyLimit)) || !props.currency) {
      return <CurrencyLimitSkeleton />;
    }

    return (
      <ScrollView>
        <LimitInfoContainer>
          {isNil(dailyLimit) ? (
            <LimitInfoUnset
              limitTitle={strings['limitsSettingsScreen.limitDailyInfo']}
              onAction={() => {
                props.onCreatePress(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  props.code! as CryptoCurrencyCode,
                  WalletLimitPeriod.Daily,
                  true,
                );
              }}
            />
          ) : (
            <LimitInfo
              currency={props.currency}
              limitTitle={strings['limitsSettingsScreen.limitDailyInfo']}
              newLimitTitle={strings['limitsSettingsScreen.limitDailyInfoNew']}
              limit={dailyLimit}
              newLimit={newDailyLimit}
              remainingTime={dailyRemainingTime}
              onDeletePress={props.onDeletePress}
              onDeleteWaitingForActivationLimitPress={() => {
                if (newDailyLimit) {
                  props.onDeleteWaitingForActivationLimitPress(newDailyLimit);
                }
              }}
              onCancelDeletePress={() => {
                props.onCancelDeletePress(dailyLimit);
              }}
              onEditPress={() => {
                props.onChangePress(dailyLimit);
              }}
            />
          )}
          {isNil(monthlyLimit) ? (
            <>
              <SizedBox />
              <LimitInfoUnset
                limitTitle={strings['limitsSettingsScreen.limitMonthlyInfo']}
                onAction={() => {
                  props.onCreatePress(
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    props.code! as CryptoCurrencyCode,
                    WalletLimitPeriod.Monthly,
                    true,
                  );
                }}
              />
            </>
          ) : (
            <>
              <SizedBox />
              <LimitInfo
                currency={props.currency}
                limitTitle={strings['limitsSettingsScreen.limitMonthlyInfo']}
                newLimitTitle={
                  strings['limitsSettingsScreen.limitMonthlyInfoNew']
                }
                limit={monthlyLimit}
                newLimit={newMonthlyLimit}
                remainingTime={monthlyRemainingTime}
                onDeleteWaitingForActivationLimitPress={() => {
                  if (newMonthlyLimit) {
                    props.onDeleteWaitingForActivationLimitPress(
                      newMonthlyLimit,
                    );
                  }
                }}
                onDeletePress={props.onDeletePress}
                onEditPress={() => {
                  props.onChangePress(monthlyLimit);
                }}
                onCancelDeletePress={() => {
                  props.onCancelDeletePress(monthlyLimit);
                }}
              />
            </>
          )}
        </LimitInfoContainer>
      </ScrollView>
    );
  },
);

const LimitInfoContainer = variance(View)(() => ({
  root: {
    padding: 15,
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    height: 20,
  },
}));
