import {BigNumber} from 'bignumber.js';

export const CRYPTO_VALUE_PRECISION = 8;
export const FIAT_VALUE_PRECISION = 2;

export function formatCryptoValue(
  value: number | string,
  precision = CRYPTO_VALUE_PRECISION,
) {
  return BigNumber(value).toFixed(precision);
}

export function keepMeaningfulDigitsInCrypto(
  value: number | string,
  defaultPrecision = CRYPTO_VALUE_PRECISION,
) {
  return keepMeaningfulDigits(value, defaultPrecision);
}

export function formatFiatValue(
  value: number | string,
  precision = FIAT_VALUE_PRECISION,
) {
  return BigNumber(value).toFixed(precision);
}

export function keepMeaningfulDigitsInFiat(
  value: number | string,
  defaultPrecision = FIAT_VALUE_PRECISION,
) {
  return keepMeaningfulDigits(value, defaultPrecision);
}

export function currencyGraphFormatter(
  value: number,
  min: number,
  max: number,
) {
  const precision = getMeaningfulPrecision(
    BigNumber(max).minus(min),
    FIAT_VALUE_PRECISION,
  );
  return BigNumber(value).toFixed(precision);
}

function keepMeaningfulDigits(val: number | string, defaultPrecision: number) {
  const precision = getMeaningfulPrecision(BigNumber(val), defaultPrecision);
  return BigNumber(val).toFixed(precision);
}

function getMeaningfulPrecision(
  n: BigNumber,
  defaultPrecision: number,
): number {
  if (!n.isFinite() || n.abs().isGreaterThanOrEqualTo(1)) {
    return defaultPrecision;
  }

  const minNonZeroShownDecimals = 3;
  return Math.max(
    getNonZeroDecimalPosition(n) + minNonZeroShownDecimals,
    defaultPrecision,
  );
}

function getNonZeroDecimalPosition(v: BigNumber, acc = -1): number {
  return !v.isFinite() || v.isZero() || v.abs().isGreaterThanOrEqualTo(1)
    ? acc
    : getNonZeroDecimalPosition(v.multipliedBy(10), acc + 1);
}

export function formatExchangeRate(value: number | string) {
  if (Number(value) < 1) {
    return keepMeaningfulDigitsInCrypto(value);
  }

  const v = value.toString();
  const [integer, fractional] = v.split('.');
  const rightLength = CRYPTO_VALUE_PRECISION - integer.length;

  const left = integer;
  const right =
    rightLength > 0 ? fractional.slice(0, rightLength) : fractional.slice(0, 2);

  return [left, right].join('.');
}
