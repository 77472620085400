import type {
  CryptoCurrencyCode,
  CurrencyDescription,
  CurrencyStore,
  JsonRpcClient,
  Millisecond,
  NCWalletCallScheme,
  NCWalletNotificationScheme,
  Wallet,
  WalletId,
  WalletStore,
} from '@ncwallet-app/core';
import {FULFILLED, toCurrencyDescriptionFromCrypto} from '@ncwallet-app/core';
import type {AccountStore} from '@ncwallet-app/core/src/AccountStore';
import type {WalletTransactions} from '@ncwallet-app/core/src/WalletTransactionsRequestHelper';
import {BigNumber} from 'bignumber.js';
import dayjs from 'dayjs';
import {action, computed, makeObservable, observable} from 'mobx';

export enum AffiliateProgramBindingStateStatus {
  Load,
  Error,
  Ready,
}

export default class AffiliateProgramBindingState {
  @observable private _status = AffiliateProgramBindingStateStatus.Load;
  @observable private _referrals = 0;
  @observable private _lastMonthEarnings = '0';
  @observable private _lastWeakEarnings = '0';
  @observable private _advertId = '';
  @observable private _currency: CurrencyDescription | undefined;

  constructor(
    private readonly _root: {
      readonly currencyStore: CurrencyStore;
      readonly walletStore: WalletStore;
      readonly accountStore: AccountStore;
      readonly walletTransactionsRequestHelper: WalletTransactions.RequestHelper;
      readonly ncWalletJsonRpcClient: JsonRpcClient<
        NCWalletCallScheme,
        NCWalletNotificationScheme
      >;
    },
  ) {
    makeObservable(this);
  }

  get currency() {
    return this._currency;
  }

  get status() {
    return this._status;
  }

  @computed
  get haveStatistic() {
    return (
      this._referrals > 0 ||
      this._lastMonthEarnings !== '0' ||
      this._lastWeakEarnings !== '0'
    );
  }

  get referrals() {
    return this._referrals;
  }

  get lastMonthEarnings() {
    return this._lastMonthEarnings;
  }

  get lastWeakEarnings() {
    return this._lastWeakEarnings;
  }

  get advertId() {
    return this._advertId;
  }

  @action.bound
  init() {
    this._getReferralsCount();
    Promise.all([
      this._root.currencyStore.refreshCryptoCurrencies(),
      this._getAdvertId(),
      this._getStatistics(),
    ])
      .then(() => {
        const currency = this._root.currencyStore.getCryptoCurrency(
          'CTC' as CryptoCurrencyCode,
        );
        this._currency = currency && toCurrencyDescriptionFromCrypto(currency);
        this._status = AffiliateProgramBindingStateStatus.Ready;
      })
      .catch(() => {
        this._status = AffiliateProgramBindingStateStatus.Error;
      });
  }

  @action.bound
  private async _getAdvertId() {
    const res = await this._root.ncWalletJsonRpcClient.call(
      'accounts.advert_code',
      {},
    );
    if (res.success) {
      this._advertId = res.right.advert_code;
    }
  }

  @action.bound
  private async _getStatistics() {
    const walletIds = await this._getWalletsIds();
    const resp = await this._root.walletTransactionsRequestHelper.getNext(
      walletIds,
      'referral_incoming',
      undefined,
      {
        to: dayjs().valueOf() as Millisecond,
        from: dayjs().subtract(1, 'month').valueOf() as Millisecond,
      },
    );
    if (!resp.success || resp.right.items.length === 0) {
      return;
    }
    const transactions = resp.right.items;

    const {lastMonthEarning, lastWeakEarning} = transactions.reduce(
      (acc, item) => {
        if (dayjs(item.createdAt).isAfter(dayjs().subtract(1, 'week'))) {
          acc.lastWeakEarning = BigNumber(acc.lastWeakEarning)
            .plus(item.amount)
            .toString();
        }
        acc.lastMonthEarning = BigNumber(acc.lastMonthEarning)
          .plus(item.amount)
          .toString();
        return acc;
      },
      {
        lastMonthEarning: '0',
        lastWeakEarning: '0',
      },
    );

    this._lastMonthEarnings = BigNumber(lastMonthEarning).toFixed(8);
    this._lastWeakEarnings = BigNumber(lastWeakEarning).toFixed(8);
  }

  @action.bound
  private _getReferralsCount() {
    this._referrals =
      this._root.accountStore.state?.status === FULFILLED
        ? this._root.accountStore.state.result.referrals_count || 0
        : 0;
  }

  private _getWalletsIds = async () => {
    let wallets = this._root.walletStore.getWallets();
    if (!wallets) {
      await this._root.walletStore.refreshWallets();
      wallets = this._root.walletStore.getWallets();
    }
    return new Set(wallets?.map(this._walletToWalletId));
  };
  // eslint-disable-next-line @typescript-eslint/class-methods-use-this
  private _walletToWalletId = (w: Wallet): WalletId => w.id;
}
