import {
  type CurrencyDescription,
  formatCryptoValue,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  EarningMonthSvg,
  EarningsSVG,
  UserSvg,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

export type AffiliateStatisticCardProps = {
  currency: CurrencyDescription | undefined;
  referrals: number;
  lastMonthEarnings: string;
  lastWeakEarnings: string;
};
export default observer(function AffiliateStatisticCard(
  props: AffiliateStatisticCardProps,
) {
  const theme = useTheme();
  const strings = useStrings();
  const {referrals, lastWeakEarnings, lastMonthEarnings} = props;
  return (
    <Container>
      <Title>{strings['affiliateScreen.statistic']}</Title>
      <Row>
        <UserSvg style={styles.icon} color={theme.palette.uiMain} />
        <Row inner>
          <Label>{strings['affiliateScreen.joined']}</Label>
          <Value>{referrals}</Value>
        </Row>
      </Row>
      <Space />
      <Row>
        <EarningsSVG style={styles.icon} color={theme.palette.uiMain} />
        <Row inner>
          <Label>{strings['affiliateScreen.earnWeek']}</Label>
          <Value>
            {formatCryptoValue(
              lastWeakEarnings,
              props.currency?.fractionDigits,
            )}{' '}
            CTC
          </Value>
        </Row>
      </Row>
      <Space />
      <Row>
        <EarningMonthSvg style={styles.icon} color={theme.palette.uiMain} />
        <Row inner>
          <Label>{strings['affiliateScreen.earnMonth']}</Label>
          <Value>
            {formatCryptoValue(
              lastMonthEarnings,
              props.currency?.fractionDigits,
            )}{' '}
            CTC
          </Value>
        </Row>
      </Row>
    </Container>
  );
});

const styles = StyleSheet.create({
  icon: {
    flexShrink: 0,
    marginRight: 14,
  },
});

const Container = variance(View)(theme => ({
  root: {
    padding: 15,
    marginBottom: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.palette.uiSecondary,
  },
}));

const Title = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    fontSize: 16,
    marginBottom: 20,
    color: theme.palette.textMain,
  },
}));

const Space = variance(View)(() => ({
  root: {
    height: 15,
  },
}));

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  inner: {
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flex: 1,
  },
}));

const Label = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('700'),
    color: theme.palette.textMain,
    fontSize: 14,
    lineHeight: 22,
    marginRight: 10,
  },
}));

const Value = variance(Text)(theme => ({
  root: {
    ...theme.fontByWeight('500'),
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.textAdditional2,
  },
}));
