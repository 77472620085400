import type {ISODateString, OtpCode, OtpCredentials} from '@ncwallet-app/core';
import {FULFILLED, useRoot, useStrings} from '@ncwallet-app/core';
import {useAccountState} from '@ncwallet-app/core/src/AccountStore';
import {
  LIST_AUTH_APPS_ROUTE,
  PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE,
  PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE,
  PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
  PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE,
  SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
  SHOW_TWO_FA_SETTINGS_ROUTE,
  TWO_FA_WELCOME_ROUTE,
} from '@ncwallet-app/core/src/CommonNavigationScheme';
import type {ListSecuritySettingsTwoFaRoute} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {SecuritySettingsCard} from '@ncwallet-app/core/src/LargeNavigationScheme/LargeHomeStack/LargeSwitch/LargeSwitchParamList';
import {TwoFaProviderKind} from '@ncwallet-app/core/src/TwoFaHelper';
import dayjs from 'dayjs';
import {noop} from 'lodash';
import {useCallback, useRef} from 'react';

import {useBaseErrorDescription} from '../../../../CommonNavigationContainers/hooks';
import type {LargeSwitchBindingProps} from '../../../../LargeNavigationRoot/LargeHomeStack/bindings/LargeSwitch/LargeSwitchBindingProps';
import type {TwoFaCardProps} from '../../../../screens/LgSecuritySettingsScreen/TwoFaCard/TwoFaCard';
import {EnableTwoFaFlow} from '../../../../screens/LgSecuritySettingsScreen/TwoFaCard/TwoFaCard';
import {useSetTwoFaGenerateSecret} from '../../../hooks';
import {useSendTelegramOtp} from '../../../hooks/useSendTelegramOtp';
import {useTwoFaSettingsState} from './useTwoFaSettingsState';

// eslint-disable-next-line import-x/prefer-default-export
export const useLgSecuritySettingsTwoFaCardBindingState = (
  props: LargeSwitchBindingProps<'ListSecuritySettings'>,
): TwoFaCardProps => {
  const {
    getSecret,
    getOtpAuthUri,
    getError,
    getHasAuthenticator,
    onOpenAuthenticator,
    onDirectlyOpenAuthenticator,
    onInstallAuthenticator,
    onCopySecret,
    secretBox,
  } = useSetTwoFaGenerateSecret();
  const root = useRoot();
  const account = useAccountState().account;
  const strings = useStrings();
  const {navigation, route} = props;
  const {onTwoFaEnabled} = route.params ?? {};
  const {sendOtp, interval, resetInterval} = useSendTelegramOtp();

  const [getTwoFaError, setTwoFaError, resetError] = useBaseErrorDescription();
  const isSubmittingRef = useRef(false);
  const onDisableTwoFaSubmit = useCallback(
    async (code: string) => {
      if (isSubmittingRef.current) {
        return;
      }
      isSubmittingRef.current = true;
      const delete_ = await root.ncWalletJsonRpcClient.call(
        'accounts.totp.delete',
        {
          code_2fa: code as OtpCode,
          utc_2fa: dayjs().utc().toISOString() as ISODateString,
        },
      );
      isSubmittingRef.current = false;
      if (!delete_.success) {
        setTwoFaError(delete_.left);
      } else {
        root.flashMessage.showMessage({
          title:
            strings[
              'secureTwoFactorAuthScreen.disableTwoFactorAuth.successMessage'
            ],
          variant: 'danger',
        });
        await root.jsonSecureKeyValueStore.delete('twoFaSecret');
        secretBox.set(undefined);

        navigation.setParams({
          focusedTwoFa: {kind: TWO_FA_WELCOME_ROUTE},
        });
      }
    },
    [navigation, strings, root, setTwoFaError, secretBox],
  );

  const onEnableTwoFaSubmit = useCallback(
    async (code: string) => {
      const secret = secretBox.get();
      if (secret === undefined || isSubmittingRef.current) {
        return null;
      }

      const payload: OtpCredentials = {
        code: code as OtpCode,
        utc_2fa: dayjs().utc().toISOString() as ISODateString,
        channel: route.params?.providerKind,
      };

      if (root.accountStore.state?.status !== FULFILLED) {
        return;
      }

      if (!root.accountStore.state.result.tfa) {
        payload.secret = secret;
      }

      isSubmittingRef.current = true;
      const set_ = await root.ncWalletJsonRpcClient.call(
        'accounts.totp.set',
        payload,
      );
      isSubmittingRef.current = false;
      if (!set_.success) {
        setTwoFaError(set_.left);
        return;
      }

      await root.twoFaSettingsState.refresh();
      root.flashMessage.showMessage({
        title:
          strings[
            'secureTwoFactorAuthScreen.activateTwoFactorAuth.successMessage'
          ],
        variant: 'success',
      });

      if (onTwoFaEnabled) {
        root.navigation.navigate(onTwoFaEnabled.toString());
      } else {
        navigation.setParams({
          focusedCard: SecuritySettingsCard.TwoFa,
          focusedTwoFa: {
            kind: SHOW_TWO_FA_SETTINGS_ROUTE,
          },
        });
        return;
      }
    },
    [
      secretBox,
      root,
      strings,
      onTwoFaEnabled,
      setTwoFaError,
      navigation,
      route.params?.providerKind,
    ],
  );

  const showTwoFaStatus = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      focusedTwoFa: {kind: SHOW_TWO_FA_SETTINGS_ROUTE},
    });
  }, [navigation]);

  const showGeneratedSecret = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      focusedTwoFa: {
        kind: SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
        params: {onTwoFaEnabled},
      },
    });
  }, [navigation, onTwoFaEnabled]);

  const listAuthApps = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      focusedTwoFa: {kind: LIST_AUTH_APPS_ROUTE},
    });
  }, [navigation]);

  const promptOtpToEnableTwoFa = useCallback(() => {
    const secret = getSecret();
    if (secret === undefined) {
      return;
    }
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      focusedTwoFa: {
        kind: PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
        params: {
          secret,
        },
      },
    });
  }, [getSecret, navigation]);

  const handleTwoFaEnable = useCallback(() => {
    navigation.setParams({
      focusedCard: SecuritySettingsCard.TwoFa,
      focusedTwoFa: {kind: PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE},
    });
  }, [navigation]);

  const twoFaFlowState = route.params?.focusedTwoFa
    ? getTwoFaFlowState(route.params.focusedTwoFa)
    : account?.tfa
      ? EnableTwoFaFlow.Settings
      : EnableTwoFaFlow.Welcome;

  const twoFaSettingsProps = useTwoFaSettingsState(
    props,
    (account?.tfa &&
      twoFaFlowState !== EnableTwoFaFlow.PromptOtpToChangeTwoFaSettings) ||
      twoFaFlowState !== EnableTwoFaFlow.PromptOtpToDisable,
  );

  const onChangeTwoFaSettingsSubmit = useCallback(
    async (code: string) => {
      if (
        route.params?.focusedTwoFa?.kind !==
          PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE ||
        isSubmittingRef.current
      ) {
        return;
      }

      isSubmittingRef.current = true;
      const res = await root.twoFaSettingsState.update(
        code as OtpCode,
        route.params.focusedTwoFa.params.excluded,
        route.params.focusedTwoFa.params.included,
      );
      isSubmittingRef.current = false;

      if (!res.success) {
        setTwoFaError(res.left);
      } else {
        navigation.setParams({
          focusedCard: SecuritySettingsCard.TwoFa,
          focusedTwoFa: {kind: SHOW_TWO_FA_SETTINGS_ROUTE},
        });

        root.flashMessage.showMessage({
          title: strings['promptOtpToChange2FASettings.successMessage'],
          variant: 'success',
        });
      }
    },
    [navigation, root, route.params, setTwoFaError, strings],
  );

  const selectedTwoFaProvider = useCallback(() => {
    if (root.accountStore.state?.status !== FULFILLED) {
      return;
    }

    return root.accountStore.state.result.tfa
      ? root.twoFaSettingsState.currentTwoFaProvider
      : undefined;
  }, [root]);

  const handleTwoFaProviderSelect = useCallback(
    (kind: TwoFaProviderKind) => {
      if (kind === TwoFaProviderKind.Telegram) {
        root.twoFaSettingsState.setCurrentTwoFaProvider(kind);
        const account =
          root.accountStore.state?.status === FULFILLED &&
          root.accountStore.state.result;

        if (!account) {
          return;
        }

        if (!account.telegram_username) {
          navigation.navigate('PromptLinkToTelegram');
          return;
        }

        navigation.setParams({
          focusedCard: SecuritySettingsCard.TwoFa,
          providerKind: TwoFaProviderKind.Telegram,
          focusedTwoFa: {
            kind: PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE,
            params: {},
          },
        });
        return;
      }

      if (selectedTwoFaProvider() === TwoFaProviderKind.Telegram) {
        navigation.setParams({
          focusedCard: SecuritySettingsCard.TwoFa,
          providerKind: root.twoFaSettingsState.prevTwoFaProvider,
          focusedTwoFa: {kind: PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE},
        });
        return;
      }

      if (selectedTwoFaProvider()) {
        navigation.setParams({
          focusedCard: SecuritySettingsCard.TwoFa,
          providerKind: root.twoFaSettingsState.prevTwoFaProvider,
          focusedTwoFa: {kind: PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE},
        });
        return;
      }

      navigation.setParams({
        focusedCard: SecuritySettingsCard.TwoFa,
        providerKind: kind,
        focusedTwoFa: {
          kind: SHOW_TWO_FA_GENERATED_SECRET_ROUTE,
          params: {providerKind: kind},
        },
      });
    },
    [navigation, root, selectedTwoFaProvider],
  );

  const handleSendTelegramOpt = useCallback(
    async (withSecret = true) => {
      const secret = getSecret();
      if (secret === undefined) {
        return;
      }

      try {
        await sendOtp(
          root.twoFaSettingsState.currentTwoFaProvider ?? null,
          {forceChannel: true},
          withSecret ? secret : undefined,
        );
      } catch {
        /* empty */
      }
    },
    [sendOtp, root.twoFaSettingsState.currentTwoFaProvider, getSecret],
  );

  return {
    onEnableTwoFaSubmit,
    getSecret,
    getOtpAuthUri,
    getError,
    getHasAuthenticator,
    onOpenAuthenticator,
    onDirectlyOpenAuthenticator,
    onInstallAuthenticator,
    onCopySecret,
    getTwoFaError,
    onChangeTwoFaSettingsSubmit,
    onEnterTwoFaCode: noop,
    onDisableTwoFaSubmit,
    twoFaEnabled: !!account?.tfa,
    resetError: resetError,
    twoFaFlowState,
    showTwoFaStatus,
    showGeneratedSecret,
    listAuthApps,
    promptOtpToEnableTwoFa,
    handleTwoFaEnable,
    twoFaSettingsProps,
    providerKind: route.params?.providerKind,
    currentTwoFaProvider: root.twoFaSettingsState.currentTwoFaProvider,
    prevTwoFaProvider: root.twoFaSettingsState.prevTwoFaProvider,
    selectedTwoFaProvider: selectedTwoFaProvider,
    handleSendTelegramOpt,
    availableTwoFaProviders:
      root.twoFaSettingsState.getAvailableProviders(account),
    onTwoFaProviderSelect: handleTwoFaProviderSelect,
    interval,
    resetInterval,
  };
};

function getTwoFaFlowState(
  route: ListSecuritySettingsTwoFaRoute,
): EnableTwoFaFlow {
  switch (route.kind) {
    case TWO_FA_WELCOME_ROUTE:
      return EnableTwoFaFlow.Welcome;
    case SHOW_TWO_FA_SETTINGS_ROUTE:
      return EnableTwoFaFlow.Settings;
    case SHOW_TWO_FA_GENERATED_SECRET_ROUTE:
      return EnableTwoFaFlow.Start;
    case LIST_AUTH_APPS_ROUTE:
      return EnableTwoFaFlow.Auth;
    case PROMPT_OTP_TO_ENABLE_TWO_FA_ROUTE:
      return EnableTwoFaFlow.SetCode;
    case PROMPT_OTP_TO_DISABLE_TWO_FA_ROUTE:
      return EnableTwoFaFlow.PromptOtpToDisable;
    case PROMPT_OTP_TO_CHANGE_TWO_FA_SETTINGS_ROUTE:
      return EnableTwoFaFlow.PromptOtpToChangeTwoFaSettings;
    case PROMPT_SELECT_TWO_FA_PROVIDER_ROUTE:
      return EnableTwoFaFlow.PromptSelectTwoFaProvider;
  }
}
