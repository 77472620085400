import _ from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';
import {nanoid} from 'nanoid';

import type {FlashMessage, Message, RequestedMessage} from './FlashMessage';

export default class FlashMessageImpl implements FlashMessage {
  private readonly _messages = observable.map<string, Message>([], {
    deep: false,
    name: 'FlashMessageImpl#_messages',
  });

  private static _DEFAULT_TIMEOUT = 3000;

  constructor() {
    makeObservable(this);
  }

  @computed
  get messages() {
    return [...this._messages.values()].reverse();
  }

  private _getContentString(message: RequestedMessage): string {
    const sortedObject = _(message)
      .omit(['id', 'timeout', 'onPress'])
      .toPairs()
      .sortBy(([key]) => key)
      .fromPairs()
      .value();
    return JSON.stringify(sortedObject);
  }

  getMessages = () => this.messages;

  showMessage = action((message: RequestedMessage) => {
    const contentString = this._getContentString(message);
    const id = message.id ?? nanoid();
    const newMessage: Message = {
      timeout: FlashMessageImpl._DEFAULT_TIMEOUT,
      ...message,
      id,
      contentString,
    };

    for (const message of this._messages.values()) {
      if (message.contentString === contentString) {
        {
          // updating existing messages with minor time changing
          this._messages.set(message.id, {
            ...newMessage,
            timeout:
              message.timeout % 2 ? message.timeout - 1 : message.timeout + 1,
            id: message.id,
          });
          return;
        }
      }
    }

    this._messages.set(id, newMessage);
  });

  removeMessage = action((id: string) => {
    this._messages.delete(id);
  });
}
