import {urlLinks} from '@ncwallet-app/core';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';
import {Linking} from 'react-native';

import {AffiliateProgramBindingStateStatus} from '../../../Navigation/HomeStack/AffiliateProgramBinding/AffiliateProgramBindingState';
import {useAffiliateProgramBindingState} from '../../../Navigation/HomeStack/AffiliateProgramBinding/useAffiliateProgramBindingState';
import AffiliateProgramScreen from '../../../screens/UserSettingsScreens/AffiliateProgramScreen';
import {useBaseActions} from '../../../shared/useBaseActions';

export default observer(function AffiliateProgramBinding() {
  const {share, shareAvailable, onCopy} = useBaseActions();
  const state = useAffiliateProgramBindingState();
  const onMore = useCallback(() => {
    void Linking.openURL(urlLinks.affiliate);
  }, []);

  return (
    <AffiliateProgramScreen
      currency={state.currency}
      isLoading={state.status === AffiliateProgramBindingStateStatus.Load}
      onShare={share}
      firstLink={urlLinks.inviteLand + state.advertId}
      secondLink={urlLinks.inviteApp + state.advertId}
      telegramLink={urlLinks.inviteTelegram + state.advertId}
      onCopy={onCopy}
      shareShown={shareAvailable}
      haveStatistic={state.haveStatistic}
      onMore={onMore}
      referrals={state.referrals}
      lastMonthEarnings={state.lastMonthEarnings}
      lastWeakEarnings={state.lastWeakEarnings}
    />
  );
});
