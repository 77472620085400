import {
  formatCryptoValue,
  useStrings,
  useTheme,
  variance,
} from '@ncwallet-app/core';
import {
  Button,
  ButtonColor,
  ButtonVariant,
  LG_BREAKPOINT,
} from '@ncwallet-app/ui/src';
import {
  ArrowLeftWide,
  ArrowRightSmall,
} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {ExchangeErrorText} from '@ncwallet-app/ui/src/components/atoms/exchangeComponents';
import {isNil} from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import ExchangeCard from './ExchangeCard';
import type {ExchangeCryptoConfirmScreenProps} from './ExchangeCryptoConfirmScreen';
import ExchangeDetails from './ExchangeDetails';

export type ExchangeCryptoConfirmScreenWideProps =
  ExchangeCryptoConfirmScreenProps & {
    onBackPress: () => void;
    onRefresh: () => void;
    buttonKind: 'edit' | 'refresh';
  };

export default observer(function ExchangeCryptoConfirmScreenWide(
  props: ExchangeCryptoConfirmScreenWideProps,
) {
  const {
    cryptoFrom,
    currencyFrom,
    valueFrom,
    cryptoTo,
    currencyTo,
    valueTo,
    exchangeRate,
    date,
    fee,
    onEdit,
    onRefresh,
    onSubmit,
  } = props;
  const strings = useStrings();
  const theme = useTheme();

  return (
    <Root>
      <Inner>
        <Header>
          <BackButton
            title={strings['screenTitle.exchangeConfirm']}
            onPress={props.onBackPress}
            Icon={ArrowLeftWide}
            iconCustomColor={theme.palette.uiAdditional1}
          />
        </Header>
        <Row>
          <ExchangeInfoView>
            <ExchangeCard
              value={formatCryptoValue(valueFrom, cryptoFrom?.decimals)}
              label={strings['exchangeCrypto.from.label']}
              crypto={currencyFrom}
            />
            <ExchangeIconContainer>
              <ArrowRightSmall
                color={theme.palette.uiSecondary}
                style={{transform: [{rotateZ: '90deg'}]}}
              />
            </ExchangeIconContainer>
            <ExchangeCard
              value={formatCryptoValue(valueTo, cryptoTo?.decimals)}
              label={strings['exchangeCrypto.to.label']}
              crypto={currencyTo}
            />
          </ExchangeInfoView>
          <SizedBox />
          <ExchangeDetailsView>
            <ExchangeDetails
              exchangeRate={exchangeRate}
              date={date}
              fee={fee}
            />
            <ExchangeErrorText>{props.error}</ExchangeErrorText>
          </ExchangeDetailsView>
        </Row>
        <ButtonGroupView>
          <ExchangeButton
            title={
              props.buttonKind === 'refresh'
                ? strings['exchangeCrypto.update']
                : strings['exchangeComfirmScreen.edit']
            }
            onPress={props.buttonKind === 'refresh' ? onRefresh : onEdit}
            variant={ButtonVariant.PrimaryLight}
          />
          <SizedBox />
          <ExchangeButton
            disabled={!isNil(props.error)}
            title={strings['exchangeCrypto.submit']}
            onPress={onSubmit}
            variant={ButtonVariant.Highlighted}
            color={ButtonColor.Secondary}
            testID="exchange-submit-btn"
          />
        </ButtonGroupView>
      </Inner>
    </Root>
  );
});

const Root = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.uiPrimary,
      },
    }),
  },
}));

const Inner = variance(View)(theme => ({
  root: {
    flex: 1,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        backgroundColor: theme.palette.background,
        borderRadius: 10,
        padding: 30,
        ...theme.bar(10),
      },
    }),
  },
}));

const Header = variance(View)(() => ({
  root: {
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const BackButton = variance(Button)(() => ({
  root: {
    padding: 0,
    borderWidth: 0,
    justifyContent: 'flex-start',
    fontSize: 18,
    lineHeight: 22,
  },
}));

const Row = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
  },
}));

const ExchangeInfoView = variance(View)(theme => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 40,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
        padding: 0,
      },
    }),
  },
}));

const ExchangeIconContainer = variance(View)(() => ({
  root: {
    marginVertical: 15,
  },
}));

const ButtonGroupView = variance(View)(() => ({
  root: {
    flexDirection: 'row',
    alignItems: 'stretch',
    marginTop: 'auto',
  },
}));

const SizedBox = variance(View)(() => ({
  root: {
    width: 20,
    minWidth: 20,
  },
}));

const ExchangeButton = variance(Button)(() => ({
  root: {
    flex: 1,
  },
}));

const ExchangeDetailsView = variance(View)(theme => ({
  root: {
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flex: 1,
      },
    }),
  },
}));
