/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type {CryptoCurrencyCode, InOutCurrency} from '@ncwallet-app/core';
import {useStrings, variance} from '@ncwallet-app/core';
import type {AddressType} from '@ncwallet-app/core/src/NCWalletServer/AddressInfo';
import {Button, ButtonVariant, LG_BREAKPOINT} from '@ncwallet-app/ui';
import {LinkSvg, QrSvg} from '@ncwallet-app/ui/src/assets/svg/colorless';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {View} from 'react-native';

import {CommentForm} from './CommentForm';
import ReceiveAddressType from './ReceiveAddressType';
import ReceiveWarning from './ReceiveWarning';
import {useReceiveScreenActions} from './useReceiveScreenActions';
import {WalletInfo} from './WalletInfo';

export type AddressTabViewProps = {
  walletHeader: React.ReactNode;
  walletAddress: string;
  onCopy: (data: string) => void;
  onShare: (data: string) => void;
  onAddresses: () => void;
  onAddressTypePress: () => void;
  addressNameShown: boolean;
  addressName?: string;
  addressType?: AddressType;
  comment: string;
  goToGenerateLink: () => void;
  showQr?: () => void;
  onChangeComment: (comment: string) => void;
  currencyCode?: CryptoCurrencyCode;
  networkCurrency?: CryptoCurrencyCode;
  networks?: InOutCurrency[] | undefined;
  showNetwork?: boolean;
  onSaveCommentPress: () => void;
  shareShown: boolean;
  isBitcoinDefaultNetwork: boolean;
  contractType: string | null | undefined;
  onFocus:
    | ((options?: {x: number; y: number; animated: boolean}) => void)
    | undefined;
};

export const AddressTabView = observer((props: AddressTabViewProps) => {
  const {
    walletHeader,
    walletAddress,
    onShare,
    onAddresses,
    networks,
    addressNameShown,
    addressName,
    isBitcoinDefaultNetwork,
    showNetwork,
    goToGenerateLink,
    onAddressTypePress,
    showQr,
    contractType,
  } = props;
  const strings = useStrings();

  const {copyAddressThrottled, shareThrottled} = useReceiveScreenActions(
    onShare,
    walletAddress,
  );

  return (
    <Container>
      <AddressTabHeader>
        {walletHeader}
        <ReceiveAddressType
          addressName={addressName}
          onAddressTypePress={onAddressTypePress}
          addressNameShown={addressNameShown}
          networks={networks}
          contractType={contractType}
        />
      </AddressTabHeader>
      {showNetwork && (
        <ReceiveWarning
          network={addressName!}
          isBitcoinDefaultNetwork={isBitcoinDefaultNetwork}
        />
      )}
      <AddressTabContent>
        <WalletInfo
          goToAddresses={onAddresses}
          shareShown={props.shareShown}
          addressType={props.addressType}
          label={strings['receiveCryptoScreen.walletAddressLabel']}
          info={walletAddress}
          onCopy={copyAddressThrottled}
          onShare={shareThrottled}
        />
        <CommentForm
          onChangeComment={props.onChangeComment}
          comment={props.comment}
          onSavePress={props.onSaveCommentPress}
          onFocus={props.onFocus}
        />
      </AddressTabContent>
      <AddressTabFooter>
        <QrButton
          variant={ButtonVariant.PrimaryLight}
          onPress={showQr}
          Icon={QrSvg}
          title={strings['receiveCryptoScreen.showQr']}
        />
        <LinkButton
          variant={ButtonVariant.Primary}
          onPress={goToGenerateLink}
          Icon={LinkSvg}
          title={strings['generatePayMeLink.screenTitle']}
        />
      </AddressTabFooter>
    </Container>
  );
});

const Container = variance(View)(() => ({
  root: {
    flex: 1,
    gap: 20,
  },
}));

const AddressTabHeader = variance(View)(theme => ({
  root: {
    gap: 20,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
      },
    }),
  },
}));

const AddressTabContent = variance(View)(theme => ({
  root: {
    flex: 1,
    gap: 20,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        paddingHorizontal: 0,
      },
    }),
  },
}));

const AddressTabFooter = variance(View)(theme => ({
  root: {
    gap: 20,
    paddingHorizontal: 15,
    ...theme.mediaQuery({
      [LG_BREAKPOINT]: {
        flexDirection: 'row',
        paddingHorizontal: 0,
      },
    }),
  },
}));

const QrButton = variance(Button)(() => ({
  root: {
    flex: 1,
  },
}));

const LinkButton = variance(Button)(() => ({
  root: {
    flex: 1,
  },
}));
