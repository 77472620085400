import type React from 'react';
import {useEffect} from 'react';
import {Keyboard} from 'react-native';
import type {ScrollView} from 'react-native-gesture-handler';

// eslint-disable-next-line import-x/prefer-default-export
export const useScrollWhenKeyboardShown = (
  ref: React.RefObject<ScrollView>,
) => {
  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', event => {
      if (event.endCoordinates.height > 0) {
        ref.current?.scrollTo({
          y: event.endCoordinates.height,
          animated: true,
        });
      }
    });

    return () => {
      showSubscription.remove();
    };
  }, [ref]);
};
